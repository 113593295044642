import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { toPrettyDaysHoursMinutes } from '../../../dateTimeFormatting';
import DateBarTooltip from './DateBarTooltip';
import { getTicks } from '../DurationTickHelpers';
import { shortFormat } from './DateUnitFormatter';
import NoPlayTimePlaceholder from '../../../NoPlayTimePlaceholder';
import DateSummaryModel from './DateSummaryModel';
import colors from '../Colors';
import moment from 'moment';

export default class DateBarChart extends PureComponent {
  static propTypes = {
    data: PropTypes.instanceOf(DateSummaryModel),
    dataSet: PropTypes.oneOf(['average', 'gross']),
    onBarClick: PropTypes.func,
  };

  static defaultProps = {
    data: null,
    dataSet: 'gross',
    onBarClick: () => {},
  };

  handleClick = barInformation => {
    const { date, unit } = barInformation;

    const dateMoment = new moment.utc(date);
    const escapedUnit = unit === 'date' ? 'day' : unit;

    this.props.onBarClick(
      dateMoment.clone().startOf(escapedUnit).toDate(),
      dateMoment.clone().endOf(escapedUnit).toDate()
    );
  };

  render() {
    const { model, dataSet } = this.props;

    if (!model || (model.gross.length === 0 && model.average.length === 0)) {
      return <NoPlayTimePlaceholder />;
    }

    const chartData = dataSet === 'gross' ? model.gross : model.average;
    const max =
      dataSet === 'gross'
        ? model.maximumGrossDuration
        : model.maximumAverageDuration;
    const ticks = getTicks(max);
    const dataKeys = model.dataKeys;
    const unit = model.unit;

    const bars = dataKeys.map((k, i) => (
      <Bar
        dataKey={k}
        stackId='games'
        fill={colors[i]}
        key={k}
        onClick={this.handleClick}
      />
    ));

    const legend =
      model.dataKeys && model.dataKeys.length > 1 ? <Legend /> : null;

    return (
      <ResponsiveContainer aspect={3}>
        <BarChart data={chartData}>
          <XAxis
            dataKey='date'
            tickFormatter={tick => shortFormat(tick, unit)}
          />
          <YAxis
            tickFormatter={tick => toPrettyDaysHoursMinutes(tick, true)}
            ticks={ticks}
            type='number'
            domain={[0, 'dataMax']}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip
            content={<DateBarTooltip dataSet={dataSet} />}
            isAnimationActive={false}
          />
          {legend}
          {bars}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
