import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Button, Icon, Menu} from 'semantic-ui-react';

export default class AccordionMenuBar extends PureComponent {
    static propTypes = {
        onLoadMore: PropTypes.func,
        showLoadMore: PropTypes.bool,
        onCollapse: PropTypes.func,
        onExpand: PropTypes.func,
    };

    static defaultProps = {
        onLoadMore: () => {},
        onCollapse: () => {},
        onExpand: () => {},
        showLoadMore: false,
    }

    render() {

        const {onLoadMore, showLoadMore, onExpand, onCollapse} = this.props;

        return (
            <Menu fluid size="tiny">
                <Menu.Item>
                    <Button.Group size="small" basic>
                        <Button icon onClick={() => onExpand()} title="Expand all">
                            <Icon name="folder open outline"/>
                        </Button>
                        <Button icon onClick={() => onCollapse()} title="Close all">
                            <Icon name="folder outline" />
                        </Button>
                    </Button.Group>
                </Menu.Item>
                <Menu.Item position="right">
                    <Button icon labelPosition='right' size="tiny" onClick={() => onLoadMore()} disabled={!showLoadMore}>
                        <Icon name='angle double down' />More
                    </Button>
                </Menu.Item>
            </Menu>
        )
    }
}