import moment from 'moment';

export function shortFormat(date, unit) {
    switch(unit) {
        case 'date':
            return new moment.utc(date).format('l');
        case 'week':
            return new moment.utc(date).startOf('week').format('l');
        case 'month':
            return new moment.utc(date).format('MMM YY');
        case 'quarter':
            return new moment.utc(date).format('[Q]Q YY');
        default:
            return new moment.utc(date);
    }
}

export function longFormat(date, unit) {
    switch(unit) {
        case 'date':
            return new moment.utc(date).format('l');
        case 'week':
            return `${new moment.utc(date).startOf('week').format('l')} - ${new moment.utc(date).endOf('week').format('l')}`;
        case 'month':
            return new moment.utc(date).format('MMMM YYYY');
        case 'quarter':
            return new moment.utc(date).format('[Q]Q YYYY');
        default:
            return new moment.utc(date);
    }
}