import React, { Fragment, useCallback } from 'react';
import { Card, Dimmer, Loader } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

import { userRoute } from '../../../store/siteNavigation';
import UserCard from '../../../common/cards/user/UserCard';
import { useUsers } from '../../../common/hooks/useUsers';

const UsersIndexContainer = () => {
  const navigate = useNavigate();

  const [{ data: users, isLoading: areUsersLoading }] = useUsers();

  const viewProfile = useCallback(
    username => navigate(userRoute(username)),
    [navigate]
  );

  return (
    <Fragment>
      <Dimmer active={areUsersLoading}>
        <Loader />
      </Dimmer>
      <Card.Group centered>
        {users.map(u => {
          return (
            <UserCard
              key={u._id}
              user={u}
              onClick={() => viewProfile(u.username)}
            />
          );
        })}
      </Card.Group>
    </Fragment>
  );
};

export default UsersIndexContainer;
