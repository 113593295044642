export function getTicks(max) {

    const data = getLimitAndStep(max);

    return buildTicks(data.limit, data.step);
}

function getLimitAndStep(max) {
    let data = {
        limit: max,
        step: max,
    };

    if(max <= 60) {
        data.limit = 60;
        data.step = 30;
    } else if(max <= 300) {
        data.limit = 300;
        data.step = 60;
    } else if(max <= 1800) {
        data.limit = 1800;
        data.step = 300;
    } else if(max <= 7200) {
        data.limit = Math.ceil(max/900) * 900;
        data.step = 900;
    } else if(max <= 10800) {
        data.limit = Math.ceil(max/1800) * 1800;
        data.step = 1800;
    } else if(max <= 21600) {
        data.limit = Math.ceil(max/3600) * 3600;
        data.step = 3600;
    } else if(max <= 43200) {
        data.limit = Math.ceil(max/3600) * 3600;
        data.step = 7200;
    } else if(max <= 86400) {
        data.limit = Math.ceil(max/3600) * 3600;
        data.step = 14400;
    } else {
        data.limit = Math.ceil(max/86400) * 86400;
        data.step = 43200;
    }

    return data;
}

function buildTicks(max, step) {

    let ticks = [];

    for(let i=0; i <= max; i += step) {
        ticks.push(i);
    }

    return ticks;
}