import { useEffect, useReducer } from 'react';
import { users as userApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useUsers = () => {
  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  useEffect(() => {
    const performFetch = async () => {
      try {
        dispatch({ type: FETCH });

        const response = await userApi.list();

        dispatch({
          type: FETCH_SUCCESS,
          payload: response.data,
        });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch();
  }, []);

  return [state];
};
