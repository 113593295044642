import { useState, useEffect, useReducer } from 'react';
import { games as gameApi, users as userApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useRankings = (initialType = 'users') => {
  const [type, setType] = useState(initialType);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  useEffect(() => {
    const performFetch = async type => {
      try {
        dispatch({ type: FETCH });
        const response =
          type === 'users'
            ? await userApi.getRankings()
            : await gameApi.getRankings();

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch(type);
  }, [type]);

  return [state, setType];
};
