import React, { useState } from 'react';
import moment from 'moment';

import { Header } from 'semantic-ui-react';
import ChartDateRangeMenu from '../../../../common/navigation/ChartDateRangeMenu';
import ExpandedStatisticsGrid from '../../../../common/statistics/ExpandedStatisticsGrid';
import VisualizationComponent from '../../../../common/charts/VisualizationComponent';
import NoPlayTimePlaceholder from '../../../../common/NoPlayTimePlaceholder';
import ExportUserSessionComponent from '../../common/ExportUserSessionComponent';

const UserGameInsightComponent = ({
  statistics = { totalSessions: 0, totalGames: [] },
  visualizationData = null,
  onDateChanged = () => {},
  onVisualizationChanged = () => {},
  isVisualizationLoading = false,
  isExportVisible = false,
  gameId,
}) => {
  const [startDate, setStartDate] = useState(
    moment().startOf('day').add(-1, 'weeks').toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());

  const handleDateChange = (startDate, endDate) => {
    setStartDate(new moment(startDate).format('YYYY-MM-DD'));
    setEndDate(new moment(endDate).format('YYYY-MM-DD'));

    onDateChanged(startDate, endDate);
  };

  const stats = statistics ? (
    <ExpandedStatisticsGrid
      totalActiveDuration={statistics.activeDuration}
      averageActiveDuration={statistics.averageActiveDuration}
      totalIdleDuration={statistics.idleDuration}
      averageIdleDuration={statistics.averageIdleDuration}
      totalSessions={statistics.totalSessions}
    />
  ) : (
    <NoPlayTimePlaceholder />
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ChartDateRangeMenu
          onSearch={handleDateChange}
          startDate={startDate}
          endDate={endDate}
        />
        {!!isExportVisible && (
          <ExportUserSessionComponent
            startDate={startDate}
            endDate={endDate}
            gameId={gameId}
          />
        )}
      </div>
      <Header
        as='h4'
        dividing
      >
        Summary
      </Header>
      {stats}
      <Header
        as='h4'
        dividing
      >
        Visualization
      </Header>
      <VisualizationComponent
        onChartChanged={onVisualizationChanged}
        chartModel={visualizationData}
        onDateChanged={handleDateChange}
        isLoading={isVisualizationLoading}
      />
    </>
  );
};

export default UserGameInsightComponent;
