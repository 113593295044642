import React, { Fragment, Component, createRef } from 'react';
import PropTypes from 'prop-types';
import {groupBy} from 'lodash';
import {Accordion, Dimmer, Loader, Header, Sticky} from 'semantic-ui-react';

import AccordianMenuBar from './AccordionMenuBar';
import Gallery from '../images/Gallery';
import ScreenshotPropType from './ScreenshotPropType';
import NoScreenshotsPlaceholder from './NoScreenshotsPlaceholder';

export default class AccordionGallery extends Component {
    static propTypes = {
        screenshots: PropTypes.arrayOf(PropTypes.shape(ScreenshotPropType)),
        isLoading: PropTypes.bool,
        onLoadMore: PropTypes.func,
        showLoadMore: PropTypes.bool,
        grouping: PropTypes.oneOf(['', 'user', 'game']),
    };

    static defaultProps = {
        screenshots: [],
        isLoading: false,
        onLoadMore: () => {},
        grouping: '',
    };

    contextRef = createRef();

    state = {
        expandedPanels: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            expandedPanels: [],
        }

        for(let i=0; i<1000; i++) {
            this.state.expandedPanels[i] = false;
        }
    }

    onExpand = () => {
        let results = [];

        for(let i=0; i<1000; i++) {
            results[i] = true;
        }

        this.setState(() => ({expandedPanels: results}));
    }

    onCollapse = () => {
        let results = [];

        for(let i=0; i<1000; i++) {
            results[i] = false;
        }

        this.setState(() => ({expandedPanels: results}));
    }

    toggleAccordian(index) {
        let {expandedPanels} = this.state;

        console.log(index);
        if(index < expandedPanels.length) {
            expandedPanels[index] = !expandedPanels[index];
        }

        this.setState(() => ({expandedPanels: expandedPanels}));
    }

    getGalleries = (groupingType, screenshots) => {
        // There is no grouping set, so we're just going to return all
        //  the screenshots in a single gallery
        if(groupingType !== 'user' && groupingType !== 'game') {
            return [
                <Gallery screenshots={screenshots.map(s=>({...s.screenshot, isPrivate: s.isPrivate}))} showAll={true} />
            ]
        }

        const groupingFunction = groupingType === 'game' ? s => s.game.name : s => s.user.username;

        const gameGroups = groupBy(screenshots, groupingFunction);

        let galleries = [];

        for(const gameGroup in gameGroups) {
            galleries.push( (
                <Fragment key={gameGroup}>
                    <Header as='h5' dividing>{gameGroup}</Header>
                    <Gallery screenshots={gameGroups[gameGroup].map(s=> ({...s.screenshot, isPrivate: s.isPrivate}))} showAll={true} />
                </Fragment>
            ));
        }

        return galleries;
    }

    render() {
        const {screenshots, showLoadMore, onLoadMore, isLoading, grouping} = this.props;
        const {expandedPanels} = this.state;

        if(!screenshots || screenshots.length === 0) {
            return <NoScreenshotsPlaceholder />;
        }

        const groups = groupBy(screenshots, s => new Date(s.created).toLocaleDateString());

        let panels = [];
        let index = 0;

        for(const group in groups) {
            const groupedByDate = groups[group];

            const galleries = this.getGalleries(grouping, groupedByDate);

            panels.push({
                key: group,
                active: expandedPanels[index],
                title: {
                    content: <span>{`${group} (${groupedByDate.length})`}</span>,
                    onClick: (e,d) => this.toggleAccordian(d.index),
                },
                content: {
                    content: galleries
                },
            });

            index++;
        }

        return (
            <div ref={this.contextRef}>
                <Dimmer active={isLoading}>
                    <Loader />
                </Dimmer>
                <Sticky context={this.contextRef}>
                    <AccordianMenuBar onLoadMore={onLoadMore} showLoadMore={showLoadMore} onExpand={() => this.onExpand()} onCollapse={() => this.onCollapse()}/>
                </Sticky>
                <Accordion exclusive={false} panels={panels} fluid/>
            </div>
        )
    }
}