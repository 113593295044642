import React, { Fragment, PureComponent, useCallback, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import GameHistoryMenu from './GameHistoryMenu';
import GameHistoryCardGroup from './cards/GameHistoryCardGroup';
import GameHistoryItemList from './lists/GameHistoryItemList';
import NoPlayTimePlaceholder from '../../../../common/NoPlayTimePlaceholder';

const GameHistoryComponent = ({
  history = [],
  onViewUserGameHistory = () => {},
}) => {
  if (!history || history.length === 0) {
    return <NoPlayTimePlaceholder />;
  }

  const [view, setView] = useState('cards');

  const onSwitchView = useCallback(view => setView(view), [view]);

  const historyView =
    view === 'cards' ? (
      <GameHistoryCardGroup
        history={history}
        onViewUserGameHistory={onViewUserGameHistory}
      />
    ) : (
      <GameHistoryItemList
        history={history}
        onViewUserGameHistory={onViewUserGameHistory}
      />
    );

  return (
    <Fragment>
      <GameHistoryMenu
        view={view}
        onViewTypeClick={view => onSwitchView(view)}
      />
      <Segment attached='bottom'>{historyView}</Segment>
    </Fragment>
  );
};

export default GameHistoryComponent;
