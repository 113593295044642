import React from 'react';
import { Item } from 'semantic-ui-react';
import SessionItem from './SessionItem';

const SessionItemList = ({
  sessions = [],
  showUser = false,
  onViewSession = () => {},
  onViewUser = () => {},
}) =>
  !sessions || sessions.length === 0 ? null : (
    <Item.Group divided>
      {sessions.map(s => {
        return (
          <SessionItem
            key={s._id}
            session={s}
            showUser={showUser}
            onViewSession={onViewSession}
            onViewUser={onViewUser}
          />
        );
      })}
    </Item.Group>
  );

export default SessionItemList;
