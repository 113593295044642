import React from 'react';
import { Header } from 'semantic-ui-react';
import WeeklyRankingItemList from './WeeklyRankingItemList';
import NoPlayTimePlaceholder from '../NoPlayTimePlaceholder';
import WeeklyStats from '../statistics/WeeklyStats';

const WeeklySummaryComponent = ({
  rankings = [],
  dates = [],
  stats = { totalDuration: 0, totalSessions: 0 },
  onItemClick = () => {},
}) => {
  if (stats.totalDuration <= 0) {
    return <NoPlayTimePlaceholder />;
  }

  return (
    <>
      <Header
        as='h4'
        dividing
      >
        Weekly Stats
      </Header>
      <WeeklyStats
        totalDuration={stats.totalDuration}
        totalSessions={stats.totalSessions}
        dates={dates}
      />
      <Header
        as='h4'
        dividing
      >
        This Week's Top 5
      </Header>
      <WeeklyRankingItemList
        rankings={rankings}
        onItemClick={onItemClick}
      />
    </>
  );
};

export default WeeklySummaryComponent;
