import React, { PureComponent } from 'react';
import memoize from 'memoize-one';
import PropTypes from 'prop-types';
import {
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Tooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import { toPrettyDaysHoursMinutes } from '../../../dateTimeFormatting';
import { getTicks } from '../DurationTickHelpers';
import NoPlayTimePlaceholder from '../../../NoPlayTimePlaceholder';
import HourOfDaySummaryModel from './HourOfDaySummaryModel';
import HourOfDayTooltip from './HourOfDayTooltip';
import colors from '../Colors';

export default class DayOfWeekBarChart extends PureComponent {
  static propTypes = {
    data: PropTypes.instanceOf(HourOfDaySummaryModel),
    dataSet: PropTypes.oneOf(['average', 'gross']),
  };

  static defaultProps = {
    data: null,
    dataSet: 'gross',
  };

  getHours = memoize(() => [
    '12A',
    '1A',
    '2A',
    '3A',
    '4A',
    '5A',
    '6A',
    '7A',
    '8A',
    '9A',
    '10A',
    '11A',
    '12P',
    '1P',
    '2P',
    '3P',
    '4P',
    '5P',
    '6P',
    '7P',
    '8P',
    '9P',
    '10P',
    '11P',
  ]);

  render() {
    const { model, dataSet } = this.props;

    if (!model || (model.gross.length === 0 && model.average.length === 0)) {
      return <NoPlayTimePlaceholder />;
    }

    const chartData = dataSet === 'gross' ? model.gross : model.average;
    const max =
      dataSet === 'gross'
        ? model.maximumGrossDuration
        : model.maximumAverageDuration;
    const ticks = getTicks(max);
    const dataKeys = model.dataKeys;

    const bars = dataKeys.map((k, i) => (
      <Bar
        dataKey={k}
        stackId='games'
        fill={colors[i]}
        key={k}
      />
    ));

    const legend =
      model.dataKeys && model.dataKeys.length > 1 ? <Legend /> : null;

    return (
      <ResponsiveContainer aspect={3}>
        <BarChart data={chartData}>
          <XAxis
            dataKey='hour'
            tickFormatter={tick => this.getHours()[tick]}
          />
          <YAxis
            tickFormatter={tick => toPrettyDaysHoursMinutes(tick, true)}
            ticks={ticks}
            domain={['0', 'dataMax']}
          />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip
            content={<HourOfDayTooltip dataSet={dataSet} />}
            isAnimationActive={false}
          />
          {bars}
          {legend}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
