import React, { useMemo } from 'react';
import { Image, Button, Card } from 'semantic-ui-react';

const CharacterComponent = ({
  id,
  name,
  realm,
  avatar,
  level,
  race,
  characterClass,
  onRemoveClick,
  onAddClick,
  imageSize,
  game = 'wow',
}) => {
  const image = imageSize ? (
    <Image
      size={imageSize}
      src={avatar}
      centered
    />
  ) : (
    <Image
      src={avatar}
      wrapped
      ui={false}
    />
  );

  const cardExtra = useMemo(
    () =>
      level && race && characterClass
        ? `${level} ${race} ${characterClass}`
        : null,
    [level, race, characterClass]
  );

  return (
    <Card>
      {image}
      <Card.Content>
        <Card.Header>
          {name}
          {onRemoveClick && (
            <Button
              floated='right'
              icon='trash'
              basic
              onClick={() => onRemoveClick(id, game)}
            />
          )}
          {onAddClick && (
            <Button
              floated='right'
              icon='plus'
              basic
              onClick={() => onAddClick(realm, name)}
            />
          )}
        </Card.Header>
        <Card.Meta>{realm}</Card.Meta>
      </Card.Content>
      {cardExtra && <Card.Content extra={true}>{cardExtra}</Card.Content>}
    </Card>
  );
};

export default CharacterComponent;
