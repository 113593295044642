import React, {Fragment, PureComponent} from 'react';
import PropTypes from 'prop-types';
import PlatformImage from './PlatformImage';

export default class PlatformImages extends PureComponent {

    static propTypes = {
        platforms: PropTypes.object,
    };

    static defaultProps = {
        platforms: undefined,
    };

    isPlatformEnabled = (platforms, typeName) => {
        if(!platforms) {
            return false;
        }

        switch(typeName) {
            case "windows":
                return !!(platforms.windows?.executable_32 || platforms.windows?.executable_64);
            case "mac":
                return !!(platforms.mac?.app && platforms.mac?.process);
            case "linux":
                return !!platforms.linux?.executable;
            default:
                return false;
        }
    };

    render() {
        const {platforms} = this.props;

        return (
            <Fragment>
                <PlatformImage platform="windows" isPlatformEnabled={this.isPlatformEnabled(platforms, 'windows')} key="windows" />
                <PlatformImage platform="osx" isPlatformEnabled={this.isPlatformEnabled(platforms, 'mac')} key="apple" />
                <PlatformImage platform="linux" isPlatformEnabled={this.isPlatformEnabled(platforms, 'linux')} key="linux" />
            </Fragment>
        )
    }
}

