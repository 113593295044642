import {sortBy} from 'lodash';
import colors from '../Colors';

export default class HourOfDaySummaryModel {
    constructor(summaryData) {
        this._data = summaryData;

        this._gross = [];
        this._average = [];

        const {grossResults, averageResults, dataKeys} = this._computeGrossAndAverage();

        this._gross = grossResults;
        this._average = averageResults;
        this._dataKeys = dataKeys;
        this._maxGross = Math.max(...this._gross.map(g => g.totalDuration));
        this._maxAverage = Math.max(...this._average.map(g => g.maxAverage));
    }

    get gross() {
        return this._gross;
    }

    get average() {
        return this._average;
    }

    get maximumGrossDuration() {
        return this._maxGross;
    }

    get maximumAverageDuration() {
        return this._maxAverage;
    }

    get dataKeys() {
        return this._dataKeys;
    }

    _computeGrossAndAverage = () => {
        let grossResults = [];
        let averageResults = [];
        let dataKeys = [];

        const sortedByDuration = sortBy(this._data, d => -d.totalDuration);

        const topFive = sortedByDuration.slice(0, 5);
        const others = sortedByDuration.slice(5);

        // Initialize the result arrays
        for(let i=0; i < 24; i++) {
            grossResults.push({
                hour: i,
                totalDuration: 0,
                totalSessions: 0,
            });

            averageResults.push({
                hour: i,
                totalDuration: 0,
                totalSessions: 0,
                maxAverage: 0,
                othersTotalDuration: null,
                othersTotalSessions: 0,
            });
        }

        for(const game of topFive) {
            // Push the game name into the datakeys
            dataKeys.push(game.name);
            // Build the gross week for the current game
            this._buildGrossWeek(game.name, game.hours, grossResults);
            this._buildAverageWeek(game.name, game.hours, averageResults);
        }

        for(const game of others) {
            this._buildGrossWeek(game.name, game.hours, grossResults, true);
            this._buildAverageWeek(game.name, game.hours, averageResults, true);
        }

        // Iterate over all of the latest average entities, and compute the others average
        for(let averageHour of averageResults.filter( a => a["othersTotalDuration"] !== null)) {
            averageHour["Others"] = averageHour["othersTotalDuration"] / averageHour["othersTotalSessions"];
        }

        if(others.length > 0) {
            dataKeys.push("Others");
        }

        return {grossResults, averageResults, dataKeys};
    }

    _buildGrossWeek(name, hours, grossResults, isOthers = false) {

        const key = isOthers ? "Others" : name;

        for(const hour of hours) {
            let currentHour = grossResults[hour.hour];

            if(!currentHour[key]) {
                currentHour[key] = null;
            }

            // If there's a duration, then increment the current duration
            //  We use an increment here, instead of assignment, so that
            //  we can push in "Others" here too without having to write more code
            if(hour.duration > 0) {
                currentHour[key] = (currentHour[key] || 0) + hour.duration;
            }

            currentHour.totalDuration += hour.duration;
            currentHour.totalSessions += hour.sessions;

            if(isOthers) {
                this._buildOthersTooltip(currentHour, name, hour.duration);
            }
        }
    }

    _buildAverageWeek(name, hours, averageResults, isOthers = false) {
        for(const hour of hours) {
            let currentHour = averageResults[hour.hour];

            currentHour[name] = hour.duration === 0 ? null : hour.duration / hour.sessions;
            currentHour.totalDuration += hour.duration;
            currentHour.totalSessions += hour.sessions;

            if(!isOthers) {
                currentHour.maxAverage += currentHour[name] ? currentHour[name] : 0
            } else {
                if(hour.duration > 0) {
                    currentHour.othersTotalDuration = (currentHour.othersTotalDuration || 0) + hour.duration;
                    currentHour.othersTotalSessions += hour.sessions;
                }

                this._buildOthersTooltip(currentHour, name, hour.duration/hour.sessions);
            }
        }
    }

    _buildOthersTooltip(dayEntity, name, duration) {
        // If there's no gross Others Tooltip array, create it now
        if(!dayEntity["OthersTooltip"]) {
            dayEntity["OthersTooltip"] = [];
        }

        // If there's a valid durations, then push it into the "OthersTooltip"
        //  array.  This is used for displaying game details of the other games
        //  in the tooltip
        if(duration > 0) {
            dayEntity["OthersTooltip"].push({
                name: name,
                value: duration,
                fill: colors[colors.length - 1]
            });
        }
    }
}