import React, { useMemo } from 'react';
import { Card } from 'semantic-ui-react';
import Moment from 'react-moment';

import User from '../../../views/users/User';

import { toPrettyDaysHoursMinutesRounded } from '../../dateTimeFormatting';
import GameList from '../../lists/GameList';
import ImageWithFallback from '../../images/ImageWithFallback';

const UserCard = ({ user, onClick }) => {
  const model = useMemo(() => new User(user), [user]);

  const memberSince = useMemo(
    () =>
      model.created ? (
        <Moment
          format='MMMM Do YYYY'
          date={model.created}
        />
      ) : (
        'forever ago'
      ),
    [model.created]
  );

  const timePlayed = useMemo(
    () =>
      model.stats.totalTime > 0 ? (
        <span>
          Total time played is{' '}
          <b>{toPrettyDaysHoursMinutesRounded(model.stats.totalTime)}</b>
        </span>
      ) : (
        <span>No time recorded</span>
      ),
    [model.stats.totalTime]
  );

  const lastSeen = useMemo(
    () =>
      model.stats.latestSession?.EndDate ? (
        <span>
          Last seen{' '}
          <Moment calendar>{model.stats.latestSession?.EndDate}</Moment>
        </span>
      ) : (
        <span>No gameplay yet</span>
      ),
    [model.stats.latestSession]
  );

  const topGamesContent = useMemo(
    () =>
      model.topGames?.length ? (
        <Card.Content>
          <h5>Top Played Games</h5>
          <GameList games={model.topGames} />
        </Card.Content>
      ) : null,
    [model.topGames]
  );

  return (
    <Card
      key={model._id}
      onClick={() => onClick(model.username)}
    >
      <ImageWithFallback
        size='small'
        src={model.avatar}
        fallback='/images/user.png'
        centered
        style={{ marginTop: '5px', marginBottom: '5px' }}
      />
      <Card.Content>
        <Card.Header>{model.username}</Card.Header>
        <Card.Meta>Joined {memberSince}</Card.Meta>
        <Card.Description>{timePlayed}</Card.Description>
      </Card.Content>
      {topGamesContent}
      <Card.Content extra>{lastSeen}</Card.Content>
    </Card>
  );
};

export default UserCard;
