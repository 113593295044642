import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image, Popup, Card } from 'semantic-ui-react';
import Moment from 'react-moment'
import {toPrettyDaysHoursMinutesRounded} from '../../dateTimeFormatting';
import ImageWithFallback from '../../images/ImageWithFallback';

export default class TopItemImage extends Component {

    static propTypes = {
        id: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
        totalDuration: PropTypes.number,
        totalSessions: PropTypes.number,
        lastPlayed: PropTypes.string,
        onClick: PropTypes.func,
    }

    static defaultProps = {
        onClick: () => {}
    }

    render() {
        const {id, name, image, totalDuration, totalSessions, lastPlayed, onClick } = this.props;

        if(!id) {
            return null;
        }

        const lastSeen = lastPlayed ? <span>Last seen <Moment calendar>{lastPlayed}</Moment></span> : <span>No gameplay yet</span>;
        const trigger = <ImageWithFallback bordered size="tiny" src={image} fallback="/images/user.png" style={{'cursor': 'pointer', 'marginRight': '15px'}} onClick={() => onClick(id)} />;

        return (
            <Popup trigger={trigger}>
                <Popup.Content>
                    <Card>
                        <Card.Content>
                            <Card.Header>{name}</Card.Header>
                            <Card.Description>
                                <b>{toPrettyDaysHoursMinutesRounded(totalDuration)}</b> played <br/>
                                <b>{totalSessions}</b> total sessions <br/>
                                <b>{toPrettyDaysHoursMinutesRounded(totalDuration/totalSessions)}</b> average
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>{lastSeen}</Card.Content>
                    </Card>
                </Popup.Content>
            </Popup>
        )
    }
}