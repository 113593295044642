import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import { Loader, Dimmer } from 'semantic-ui-react';
import moment from 'moment';
import memoize from 'memoize-one';

export default class SessionTimeline extends PureComponent {
  static propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    idleTime: PropTypes.arrayOf(
      PropTypes.shape({
        StartDate: PropTypes.string,
        Duration: PropTypes.number,
      })
    ),
  };

  static defaultProps = {
    idleTime: [],
  };

  computeTimeline = memoize((startDate, endDate, idleTime) => {
    let timeline = [];

    let currentRecord = ['Activity', 'Active', new moment(startDate).toDate()];

    for (const idle of idleTime) {
      const idleStartDate = new moment(idle.StartDate).toDate();
      const idleEndDate = new moment(idleStartDate)
        .add(idle.Duration, 's')
        .toDate();

      currentRecord.push(idleStartDate);

      timeline.push(currentRecord);

      currentRecord = ['Activity', 'Inactive', idleStartDate, idleEndDate];

      timeline.push(currentRecord);

      currentRecord = ['Activity', 'Active', idleEndDate];
    }

    currentRecord.push(new moment(endDate).toDate());

    timeline.push(currentRecord);

    return timeline;
  });

  render() {
    const { startDate, endDate, idleTime } = this.props;

    const timeline = this.computeTimeline(startDate, endDate, idleTime);

    return (
      <div style={{ overflowY: 'hidden', height: '75px' }}>
        <Chart
          chartType='Timeline'
          loader={
            <Dimmer>
              <Loader active={true} />
            </Dimmer>
          }
          height='100px'
          data={[
            [
              { type: 'string', id: 'Activity' },
              { type: 'string', id: 'Status' },
              { type: 'date', id: 'Start' },
              { type: 'date', id: 'End' },
            ],
            ...timeline,
          ]}
          options={{
            timeline: {
              showRowLabels: false,
              showGroupLabels: false,
              groupByRowLabel: true,
              showBarLabels: false,
              enableInteractivity: false,
              avoidOverlappingGridLines: false,
              rowLabelStyle: { fontName: 'Lato' },
              barLabelStyle: { fontName: 'Lato' },
            },
            hAxis: {
              format: 'h:mm aa',
            },
            tooltip: {
              trigger: 'none',
            },
            colors: ['#829db4', '#d3d3d3'],
          }}
        />
      </div>
    );
  }
}
