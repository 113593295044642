export function toPrettyDaysHoursMinutes(durationInSeconds, short = false) {

  const time = getTime(durationInSeconds);

  let result = '';

  if( time.days > 0 ) {
    result += `${time.days} day${time.days === 1 ? '': 's'}`;
  }

  if( time.hours > 0 ) {
    result += ` ${time.hours} ${short ? 'h' : `hour${time.hours === 1 ? '' : 's'}`}`;
  }

  if( time.minutes > 0 ) {
    result += ` ${time.minutes} ${short ? 'm' : `minute${time.minutes === 1 ? '' : 's'}`}`;
  }

  if( time.seconds > 0 ) {
    result += ` ${time.seconds} ${short ? 's' : `second${time.seconds === 1 ? '' : 's'}`}`;
  }

  return result;
};

export function toPrettyDaysHoursMinutesRounded(durationInSeconds) {

  const time = getTime(durationInSeconds);

  let secondsString = `${time.seconds} second${time.seconds === 1 ? '' : 's'}`;
  let minutesString = `${time.minutes} minute${time.minutes === 1 ? '' : 's'}`;
  let hoursString = `${time.hours} hour${time.hours === 1 ? '' : 's'}`;
  let dayString = `${time.days} day${time.days === 1 ? '' : 's'}`;

  if( time.days > 0 ) {
    return `${dayString} ${(time.hours > 0) ? hoursString : minutesString}`;
  }

  if( time.hours > 0 ) {
    return `${hoursString} ${(time.minutes > 0) ? minutesString : ''}`
  }

  if( time.minutes > 0 ) {
    return minutesString;
  }

  if( time.seconds > 0 ) {
    return secondsString;
  }

  return '';
};

function getTime(durationInSeconds) {
  let timeInSeconds = parseInt(durationInSeconds, 10);

  const days = Math.floor(timeInSeconds / 86400);
  const hours = Math.floor((timeInSeconds - (days * 86400)) / 3600);
  const minutes = Math.floor((timeInSeconds - (days * 86400) - (hours * 3600)) / 60);
  const seconds = timeInSeconds - (days * 86400) - (hours * 3600) - (minutes * 60);

  return {
    days,
    hours,
    minutes,
    seconds,
  }
}