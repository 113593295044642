import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Header } from 'semantic-ui-react';
import memoize from 'memoize-one';

import ExpandedStatisticsGrid from '../../../../common/statistics/ExpandedStatisticsGrid';
import ChartDateRangeMenu from '../../../../common/navigation/ChartDateRangeMenu';
import HourOfDaySummaryModel from '../../../../common/charts/bar/hour-of-day/HourOfDaySummaryModel';
import DateSummaryModel from '../../../../common/charts/bar/date/DateSummaryModel';
import DayOfWeekSummaryModel from '../../../../common/charts/bar/day-of-week/DayOfWeekSummaryModel';
import TopItemList from '../../../../common/lists/top-items/TopItemList';
import VisualizationComponent from '../../../../common/charts/VisualizationComponent';
import NoPlayTimePlaceholder from '../../../../common/NoPlayTimePlaceholder';

export default class GameInsightsComponent extends Component {

    static propTypes = {
        topPlayers: PropTypes.array,
        statistics: PropTypes.object,
        onDateChanged: PropTypes.func,
        onTopPlayerClicked: PropTypes.func,
        onVisualizationChanged: PropTypes.func,
        visualizationData: PropTypes.oneOfType([
            PropTypes.instanceOf(DateSummaryModel),
            PropTypes.instanceOf(DayOfWeekSummaryModel),
            PropTypes.instanceOf(HourOfDaySummaryModel),
        ]),
        isVisualizationLoading: PropTypes.bool,
    };

    static defaultProps = {
        topPlayers: [],
        statistics: { totalSessions: 0, totalGames: [] },
        visualizationData: null,
        onDateChanged: () => {},
        onTopPlayerClicked: () => {},
        onVisualizationChanged: () => {},
        isVisualizationLoading: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            startDate: '',
            endDate: '',
        };
    }

    componentDidMount() {
        this.props.onDateChanged(moment().startOf('day').add(-1, 'weeks').toDate(), moment().endOf('day').toDate());
    }

    getTopPlayers = memoize(
        (topPlayers) =>
        topPlayers.map( t => { return {
                id: t._id._id,
                name: t._id.username,
                image: t._id.avatar,
                totalDuration: t.duration,
                totalSessions: t.totalSessions,
                lastPlayed: t.lastPlayed,
            }})
    );

    handleDateChange = (startDate, endDate) => {
        this.setState( () => ({
            startDate: new moment(startDate).format("YYYY-MM-DD"),
            endDate: new moment(endDate).format("YYYY-MM-DD"),
        }));

        this.props.onDateChanged(startDate, endDate);
    }

    render() {
        const {onTopPlayerClicked, onVisualizationChanged, statistics, visualizationData, isVisualizationLoading} = this.props;
        const {startDate, endDate} = this.state;
        const topPlayers = this.getTopPlayers(this.props.topPlayers);

        const stats = statistics ?
                        <ExpandedStatisticsGrid
                            totalActiveDuration={statistics.activeDuration}
                            averageActiveDuration={statistics.averageActiveDuration}
                            totalIdleDuration={statistics.idleDuration}
                            averageIdleDuration={statistics.averageIdleDuration}
                            totalSessions={statistics.totalSessions}
                            totalPlayerOrGames={statistics.totalPlayers ? statistics.totalPlayers.length : 0}
                            show='players'
                        /> :
                        <NoPlayTimePlaceholder/>

        return (
            <>
                <ChartDateRangeMenu onSearch={this.handleDateChange} startDate={startDate} endDate={endDate} />
                <Header as="h4" dividing>Summary</Header>
                {stats}
                <Header as="h4" dividing>Top Players</Header>
                <TopItemList items={topPlayers} onItemClick={onTopPlayerClicked} />
                <Header as="h4" dividing>Visulization</Header>
                <VisualizationComponent onChartChanged={onVisualizationChanged} chartModel={visualizationData} onDateChanged={this.handleDateChange} isLoading={isVisualizationLoading} />
            </>
        )
    }
}
