import React from 'react';
import { List } from 'semantic-ui-react';
import RoundedThumbnail from '../../common/images/RoundedThumbnail';

const GameList = ({ games = [], size = '50px' }) => {
  return (
    <List horizontal>
      <List.Item>
        {games.map(tg => (
          <RoundedThumbnail
            key={tg._id}
            url={tg.boxart}
            title={tg.name}
            size={size}
            style={{ marginTop: '5px' }}
          />
        ))}
      </List.Item>
    </List>
  );
};

export default GameList;
