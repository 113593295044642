import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import {
  loginRoute,
  userRoute,
  registrationRoute,
} from '../../../store/siteNavigation';
import queryString from 'query-string';
import { useAuthentication } from '../../../store/authenticationContext';

const LoginResponseComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { completeLogin } = useAuthentication();

  // Get the response value from the query string
  const parsed = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  useEffect(() => {
    // Either there's not query string, or it doesn't contain a response entry
    if (!parsed || !parsed.login) {
      navigate(loginRoute);
      return;
    }

    const loginResponse = JSON.parse(atob(parsed.login));

    // The login response isn't valid, so bounce the user back home
    if (!loginResponse || !loginResponse.user) {
      navigate(loginRoute);
      return;
    }

    // The user is new.  Direct them to the creation page
    if (loginResponse.isNew) {
      navigate(registrationRoute(parsed.login));
    } else {
      // Set the user
      completeLogin(loginResponse.user);

      // Navigate to their page
      navigate(userRoute(loginResponse.user.username));
    }
  }, [parsed, navigate]);

  return (
    <Dimmer active>
      <Loader />
    </Dimmer>
  );
};

export default LoginResponseComponent;
