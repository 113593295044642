import React, { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Dimmer, Loader, Tab } from 'semantic-ui-react';
import moment from 'moment';

import {
  sessionRoute,
  userRoute,
  userGameRoute,
} from '../../../store/siteNavigation';

import { useGame } from '../../../common/hooks/useGame';
import { useStatistics } from '../../../common/hooks/useStatistics';
import { useDateSummary } from '../../../common/hooks/getDateSummary';
import { useScreenshotApi } from '../../../common/hooks/getScreenshots';
import { useSessionApi } from '../../../common/hooks/getSessions';
import { useVisualizationData } from '../../../common/hooks/getVisualizationData';
import { useWeeklySummary } from '../../../common/hooks/useWeeklySummary';
import { useTopPlayers } from './hooks/useTopPlayers';

import WeeklySummaryComponent from '../../../common/weekly/WeeklySummaryComponent';
import StatisticsSegment from '../../../common/statistics/StatisticsSegment';
import SessionFeedComponent from '../../../common/sessions/SessionFeedComponent';
import AccordionGallery from '../../../common/screenshots/AccordianGallery';
import GameCard from '../../../common/cards/game/GameCard';

import GameInsightsComponent from './components/GameInsightsComponent';
import SummaryComponent from '../../../common/SummaryComponent';

const GamesDetailContainer = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const heatMapStart = moment()
    .subtract(6, 'months')
    .startOf('week')
    .startOf('day')
    .toDate();
  const heatMapEnd = moment().endOf('week').endOf('day').toDate();
  const timezone = moment().format('ZZ');
  const startDate = moment().subtract(1, 'week').startOf('day').toDate();
  const endDate = moment().endOf('day').toDate();
  const chartType = 'date';

  // Get the core game
  const [{ data: game, isLoading: isGameLoading }] = useGame(id);

  const [{ data: statistics }, setStatisticsStartDate, setStatisticsEndDate] =
    useStatistics(null, id, startDate, endDate);

  const [{ data: heatMap, isLoading: isHeatMapLoading }] = useDateSummary(
    null,
    id,
    'date',
    heatMapStart,
    heatMapEnd,
    timezone
  );

  const [
    {
      data: screenshots,
      pagination: screenshotPaging,
      isLoading: isScreenshotsLoading,
    },
    loadMoreScreenshots,
  ] = useScreenshotApi(null, id);

  // Visualization properties
  const [
    { data: visualizationData, isLoading: isVisualizationLoading },
    setVisualizationType,
    setVisualizationStartDate,
    setVisualizationEndDate,
  ] = useVisualizationData(null, id, chartType, startDate, endDate, timezone);

  // Top users properties
  const [{ data: topPlayers }, setTopPlayersStartDate, setTopPlayersEndDate] =
    useTopPlayers(id, startDate, endDate);

  // Session properties
  const [
    { data: sessions, pagination: sessionPaging, isLoading: isSessionLoading },
    loadMoreSessions,
  ] = useSessionApi(null, id);

  const [{ data: summary, isLoading: isSummaryLoading }] = useWeeklySummary(
    null,
    id
  );

  const visualizationDatesChanged = useCallback(
    (startDate, endDate) => {
      setVisualizationStartDate(startDate);
      setVisualizationEndDate(endDate);
      setStatisticsStartDate(startDate);
      setStatisticsEndDate(endDate);
      setTopPlayersStartDate(startDate);
      setTopPlayersEndDate(endDate);
    },
    [
      setVisualizationStartDate,
      setVisualizationEndDate,
      setStatisticsStartDate,
      setStatisticsEndDate,
      setTopPlayersStartDate,
      setTopPlayersEndDate,
    ]
  );

  // Get the latest top 5 screenshots
  const recentScreenshots = useMemo(
    () =>
      screenshots?.length > 0
        ? screenshots.slice(0, 5).map(s => s.screenshot)
        : [],
    [screenshots]
  );

  const onViewSession = useCallback(
    id => navigate(sessionRoute(id)),
    [navigate]
  );

  const onViewUser = useCallback(
    username => navigate(userRoute(username)),
    [navigate]
  );

  const onViewUserGameHistory = useCallback(
    (username, gameId) => navigate(userGameRoute(username, gameId)),
    [navigate]
  );

  const tabs = [
    {
      menuItem: 'summary',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SummaryComponent
            latestSession={latestSession}
            dailyRankings={heatMap}
            screenshots={recentScreenshots}
            onViewSession={id => onViewSession(id)}
            isLoading={isSummaryLoading}
            onViewUser={username => onViewUser(username)}
            options={summaryOptions}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'past week',
      render: () => (
        <Tab.Pane attached='bottom'>
          <WeeklySummaryComponent
            rankings={summary.rankings}
            latestSession={latestSession}
            stats={summary.stats}
            dates={summary.dates}
            onViewSession={id => onViewSession(id)}
            isLoading={isSummaryLoading}
            onViewUser={username => onViewUser(username)}
            showUser={true}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'insights',
      render: () => (
        <Tab.Pane attached='bottom'>
          <GameInsightsComponent
            topPlayers={topPlayers}
            statistics={statistics}
            visualizationData={visualizationData}
            isVisualizationLoading={isVisualizationLoading}
            onDateChanged={visualizationDatesChanged}
            onVisualizationChanged={setVisualizationType}
            onTopPlayerClicked={username => onViewUserGameHistory(username, id)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'activity',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SessionFeedComponent
            sessions={sessions}
            isLoading={isSessionLoading}
            onLoadMore={loadMoreSessions}
            showUser={true}
            showLoadMore={sessionPaging.page !== sessionPaging.pages}
            onViewSession={id => onViewSession(id)}
            onViewUser={username => onViewUser(username)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'screenshots',
      render: () => (
        <Tab.Pane attached='bottom'>
          <AccordionGallery
            screenshots={screenshots}
            isLoading={isScreenshotsLoading}
            showLoadMore={screenshotPaging.page !== screenshotPaging.pages}
            onLoadMore={loadMoreScreenshots}
          />
        </Tab.Pane>
      ),
    },
  ];

  const summaryOptions = {
    showUser: true,
    defaultStartDate: heatMapStart,
    defaultEndDate: heatMapEnd,
  };

  const totalSessions = game?.statistics ? game.statistics.totalSessions : 0;
  const latestSession = game?.statistics ? game.statistics.latestSession : null;
  const totalTime = game?.statistics ? game.statistics.activeDuration : 0;

  return (
    <>
      <Dimmer active={isGameLoading}>
        <Loader />
      </Dimmer>
      <Grid
        columns={2}
        centered
        stackable
      >
        <Grid.Row
          only='computer'
          centered
        >
          <Grid.Column width={3}>
            <GameCard game={game} />
            <StatisticsSegment
              totalTime={totalTime}
              totalSessions={totalSessions}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Tab
              menu={{ secondary: true, attached: 'top', pointing: true }}
              panes={tabs}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          only='tablet mobile'
          centered
        >
          <Grid.Column width={7}>
            <GameCard game={game} />
          </Grid.Column>
          <Grid.Column width={7}>
            <StatisticsSegment
              totalTime={totalTime}
              totalSessions={totalSessions}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          only='tablet mobile'
          centered
        >
          <Grid.Column width={14}>
            <Tab
              menu={{
                secondary: true,
                attached: 'top',
                pointing: true,
                stackable: true,
              }}
              panes={tabs}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default GamesDetailContainer;
