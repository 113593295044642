import moment from 'moment';
import {sortBy} from 'lodash';
import colors from '../Colors';

export default class DateSummaryModel {
    constructor(dateSummaryData) {
        this._unit = 'date';

        this._gross = [];
        this._average = [];

        this._startDate = new moment();
        this._endDate = new moment();

        this._computeDateBounds(dateSummaryData);

        const model = this._buildModel(dateSummaryData);
        const {gross, average} = this._buildGrossAndAverage(model.data);

        this._gross = gross;
        this._average = average;
        this._dataKeys = model.keys;
        this._maxGross = Math.max(...this._gross.map(g => g.totalDuration));
        this._maxAverage = Math.max(...this._average.map(g => g.averageDuration));
    }

    get startDate() {
        return this._startDate;
    }

    get endDate() {
        return this._endDate;
    }

    get gross() {
        return this._gross;
    }

    get average() {
        return this._average;
    }

    get maximumGrossDuration() {
        return this._maxGross;
    }

    get maximumAverageDuration() {
        return this._maxAverage;
    }

    get dataKeys() {
        return this._dataKeys;
    }

    get unit() {
        return this._unit;
    }

    // The point of this method is to take the data that's arranged by Game, with an array of dates with durations
    //  and flip it, so that it is arranged by dates, with games and durations.
    // In addition to flipping the data, it will also break it up into buckets, based on the week differential.
    //  This upfront work here greatly simplifies the computation of the gross and average values for the chart.
    _buildModel = (summaryData) => {
        let model = {
            keys: [],
            data: {}
        };

        // Sort the supplied data by duration, so we can isolate the top 5 games
        const sortedByDuration = sortBy(summaryData, d => -d.totalDuration);

        // Create an array of keys, which will be used by recharts for the legend.  We're interested
        //  in the name of the top 5 games, and then an "Others" is there are additional games
        model.keys = [...sortedByDuration.slice(0,5).map(d=>d.name), ...(sortedByDuration.length > 5 ? ["Others"] : [])];

        // Iterate over all of the game entries
        for(let i=0; i < sortedByDuration.length; i++) {
            const game = sortedByDuration[i];
            const isTopFive = i < 5;

            for(const data of game.dates) {
                // Get the current bucket key
                let key = this._generatePartitionBucket(data);

                // If there's currently not an entry for the
                //  given partition bucket, go ahead and create
                //  one now.
                if(!model.data[key]) {
                    model.data[key] = {
                        date: data.date,
                        unit: this.unit,
                        totalDuration: null,
                        totalSessions: 0,
                        games: {},
                        others: {
                            games: {},
                            duration: null,
                            sessions: 0,
                        }
                    }
                }

                // Get the bucket for the current date
                let bucket = model.data[key];

                // If the game is a top five game, then attach it to the
                //  games property.  Otherwise, we don't care as much about
                //  it, and want to roll it into the others property
                if(isTopFive) {
                    // If the game is not associated with the current bucket, then
                    //  go ahead and create a new record now
                    if(!bucket.games[game.name]) {
                        bucket.games[game.name] = {
                            duration: null,
                            sessions: 0,
                        };
                    }

                    // Get the current game information
                    let currentGame = bucket.games[game.name];

                    // If there's a valid duration, then increment the duration
                    if(data.duration > 0) {
                        bucket.totalDuration = (bucket.totalDuration || 0) + data.duration;
                        currentGame.duration = (currentGame.duration || 0) + data.duration;
                    }
                    currentGame.sessions += data.sessions;
                    bucket.totalSessions += data.sessions;
                } else {
                    if(!bucket.others.games[game.name]) {
                        bucket.others.games[game.name] = {
                            duration: null,
                            sessions: 0,
                        };
                    }

                    let currentGame = bucket.others.games[game.name];

                    if(data.duration > 0) {
                        bucket.totalDuration = (bucket.totalDuration || 0) + data.duration;
                        currentGame.duration = (currentGame.duration || 0) + data.duration;
                        bucket.others.duration = (bucket.others.duration || 0) + data.duration;
                    }

                    currentGame.sessions += data.sessions;
                    bucket.others.sessions += data.sessions;
                    bucket.sessions += data.sessions;
                }
            }
        }

        return model;
    }

    _buildGrossAndAverage = (model) => {
        let gross = [];
        let average = [];

        for(const bucketName in model) {

            const bucket = model[bucketName];

            let grossDate = {
                date: bucket.date,
                bucket: bucketName,
                unit: bucket.unit,
                totalDuration: bucket.totalDuration,
                totalSessions: bucket.totalSessions,
            }

            let averageDate = {
                date: bucket.date,
                bucket: bucketName,
                unit: bucket.unit,
                totalDuration: bucket.totalDuration,
                totalSessions: bucket.totalSessions,
                averageDuration: null,
            }

            let totalAverageDuration = 0;

            for(const gameName in bucket.games) {
                const game = bucket.games[gameName];

                grossDate[gameName] = game.duration;
                averageDate[gameName] = game.sessions === 0 ? null : game.duration / game.sessions;

                totalAverageDuration += (averageDate[gameName] || 0);
            }

            grossDate["Others"] = bucket.others.duration;
            averageDate["Others"] = bucket.others.sessions === 0 ? null : bucket.others.duration/bucket.others.sessions;

            if(bucket.others.sessions > 0) {
                totalAverageDuration += averageDate["Others"];
            }

            averageDate.averageDuration = totalAverageDuration;

            // If there is a valid duration, then build the others tooltip
            if(bucket.others.duration) {

                for(const gameName in bucket.others.games) {
                    const game = bucket.others.games[gameName];

                    if(game.sessions <= 0) {
                        continue;
                    }

                    if(!grossDate["OthersTooltip"]) {
                        grossDate["OthersTooltip"] = [];
                    }

                    grossDate["OthersTooltip"].push({
                        name: gameName,
                        value: game.duration,
                        fill: colors[colors.length - 1]
                    });

                    if(!averageDate["OthersTooltip"]) {
                        averageDate["OthersTooltip"] = [];
                    }

                    averageDate["OthersTooltip"].push({
                        name: gameName,
                        value: game.duration/game.sessions,
                        fill: colors[colors.length - 1]
                    });
                }
            }

            gross.push(grossDate);
            average.push(averageDate);
        }

        return {gross, average};
    }

    _computeDateBounds = (data) => {
        if(!data || data.length === 0) {
            return;
        }

        const {dates} = data[0];

        this._startDate = new moment.utc(dates[0].date);
        this._endDate = new moment.utc(dates[dates.length - 1].date);

        this._startEndWeekDiff = this._endDate.diff(this._startDate, 'week');

        this._setUnit(this._startEndWeekDiff);
    }

    _generatePartitionBucket(dateEntry) {

        switch(this.unit) {
            case "date":
                return dateEntry.date.toString();
            case "week":
                return `${dateEntry.week} ${dateEntry.year}`;
            case "month":
                return `${dateEntry.month} ${dateEntry.year}`;
            case "quarter":
                const quarter = dateEntry.month <= 2 ? 1 : dateEntry.month <= 5 ? 2 : dateEntry.month <= 8 ? 3 : 4;  // month is zero-based
                return `${quarter} ${dateEntry.year}`;
            default:
                return dateEntry.date.toString();
        }
    }

    _setUnit = (weekDifferential) => {
        if(weekDifferential <= 26) {
            this._unit = 'date';
        } else if(weekDifferential <= 52) {
            this._unit = 'week';
        } else if(weekDifferential <= 192) {
            this._unit = 'month';
        } else {
            this._unit = 'quarter';
        }
    }
}
