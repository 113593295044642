import React, { useMemo } from 'react';
import { Item, Label, Icon, Segment } from 'semantic-ui-react';
import GameHistoryItem from '../../views/users/profile/history/lists/GameHistoryItem';

const createHistoryRecord = (r) => {
    const history = {
        boxart: r.image,
        id: r._id,
        name: r.name,
        totalActiveTime: r.summary.activeDuration,
        totalSessions: r.summary.totalSessions,
        latestSession : {
            EndDate: r.lastPlayed
        },
        icon: 'minus',
        change: r.change,
    };

    if(history.change < 0) {
        history.color = 'red';
        history.icon = 'arrow down';
    } else if(history.change > 0) {
        history.color = 'green';
        history.icon = 'arrow up';
    } else if(history.change === 'New') {
        history.color = 'blue';
        history.icon = 'plus';
    }

    return history;
}

const WeeklyRankingItemList = ({rankings = [], type = 'game', onItemClick = () => {}}) => {

    const histories = useMemo(() => rankings.map( r => createHistoryRecord(r)), [rankings]);

    if(!rankings) {
        return null;
    }

    return (
        <Segment.Group>
            {
                histories.map( history => {
                    return (
                        <Segment key={history._id}>
                            <Label ribbon color={history.color}>
                                <Icon name={history.icon} />{history.change}
                            </Label>
                            <Item.Group divided>
                                <GameHistoryItem history={history} onViewClick={onItemClick} type={type}/>
                            </Item.Group>
                        </Segment>
                    )
                })
            }
        </Segment.Group>
    );
}

export default WeeklyRankingItemList;
