import { useCallback, useReducer } from 'react';
import { everquest2 as eq2Api } from '../../../../api';
import {
  FETCH_WITH_RESET,
  FETCH_ERROR,
  FETCH_SUCCESS,
  FETCH_NOT_FOUND,
  apiReducer,
} from '../../../../common/reducers/apiReducer';

export const useEverquestTwoCharacter = () => {
  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  const lookupCharacter = useCallback(
    async (world, name) => {
      try {
        dispatch({ type: FETCH_WITH_RESET });

        const response = await eq2Api.getCharacter(name, world);

        dispatch({
          type: FETCH_SUCCESS,
          payload: response.data,
        });
      } catch (e) {
        if (e.response?.status === 404) {
          dispatch({
            type: FETCH_NOT_FOUND,
            message: `It doesn't look like ${name}@${world} is a valid character`,
          });
        } else {
          dispatch({ type: FETCH_ERROR, payload: e });
          console.error(e);
        }
      }
    },
    [dispatch]
  );

  return [state, lookupCharacter];
};
