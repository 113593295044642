import React from 'react';

import './HomeContainer.css';
import { Button } from 'semantic-ui-react';
import FeatureSquare from './FeatureSquare';

const HomeContainer = () => {
  return (
    <div className='home-container'>
      <div className='home-banner'>
        <div className='home-banner-jumbotron'>
          <div className='home-banner-text falling-object'>
            Delightfully Effortless Gameplay Tracking
          </div>
          <img
            className='home-banner-image shaking-object'
            src='/images/logo.webp'
          />
        </div>
        <div className='home-banner-download'>
          <Button
            size='huge'
            className='home-download-button'
            href='https://tempusgameit-production.s3.amazonaws.com/client/windows/TempusGameItSetup.exe'
          >
            Windows Download
          </Button>
          <Button
            size='huge'
            className='home-download-button'
            href='https://tempusgameit-production.s3.amazonaws.com/client/mac/TempusGameIt.pkg'
          >
            Mac Download
          </Button>
        </div>
      </div>
      <div className='home-features'>
        <div>
          <FeatureSquare
            title='Precision'
            description='Track play time down to the second. Distinguish between active and idle time'
            image='/images/precision.webp'
          />
        </div>
        <div>
          <FeatureSquare
            title='Achievements'
            description='Automatically link achievements earned from Steam games, World of Warcraft, and others'
            image='/images/achievements.webp'
            alternateBackground={true}
          />
        </div>
        <div>
          <FeatureSquare
            title='Screenshots'
            description='Automatically upload any screenshots taken while playing for a cloud-based repository of good memories and epic moments'
            image='/images/screenshots.webp'
            alternateBackground={true}
          />
        </div>
        <div>
          <FeatureSquare
            title='Visualization'
            description='Powerful charting to see game play trends. See data by week, month, year, or a totally custom range'
            image='/images/visualization.webp'
          />
        </div>
      </div>
      <div className='home-footer'>
        <div>Copyright 2024 Brickland Tech LLC</div>
        <div>
          Contact us at{' '}
          <a href='mailto:feedback@tempusgameit.com'>
            feedback@tempusgameit.com
          </a>{' '}
        </div>
        <div>
          <a href='privacy.html'>Privacy Policy</a>
        </div>
        <div>
          Join us on{' '}
          <a
            href='https://discord.gg/934jSKEyTG'
            target='_blank'
          >
            Discord
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomeContainer;
