export const FETCH = 'FETCH';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';

export const apiReducerPaged = (state, action) => {
    switch(action.type) {
        case FETCH:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: null,
                data: state.data !== null ? state.data.concat(action.payload) : action.payload,
                pagination: action.pagination,
            };
        case FETCH_ERROR:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                data: null,
            };
        default:
            throw new Error();
    }
};
