export const FETCH = 'FETCH';
export const FETCH_WITH_RESET = 'FETCH_WITH_RESET';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_NOT_FOUND = 'FETCH_NOT_FOUND';

export const apiReducer = (state, action) => {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
        error: null,
        message: undefined,
      };
    case FETCH_WITH_RESET:
      return {
        ...state,
        isLoading: true,
        error: null,
        data: null,
        message: undefined,
      };
    case FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: action.payload,
        message: undefined,
      };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        data: null,
        message: undefined,
      };
    case FETCH_NOT_FOUND:
      return {
        ...state,
        isLoading: false,
        message: action.message,
        data: null,
      };
    default:
      throw new Error();
  }
};
