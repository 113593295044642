import axios from 'axios';
import browserStorage from './common/browserStorage';

console.log(`URL: ${process.env.REACT_APP_API_URL}`);

export const baseURL = `https://${process.env.REACT_APP_API_URL}`;

const axiosInstance = axios.create({
  baseURL: `${baseURL}/api`,
});

axiosInstance.interceptors.request.use(config => {
  const token = browserStorage.getItem('token');

  if (token) {
    let headers = config.headers?.common || config.headers || {};

    headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

const baseAxiosInstance = axios.create({
  baseURL: `${baseURL}`,
});

export const authentication = {
  login: provider => baseAxiosInstance.get(`/auth/${provider}`),
  logout: () => baseAxiosInstance.get(`/logout`),
  register: user =>
    baseAxiosInstance.post(`/register?redirectTo=/auth/response`, user),
};

export const users = {
  list: () => axiosInstance.get(`/users`),
  getUser: username => axiosInstance.get(`/users/${username}`),
  getWeeklySummary: username =>
    axiosInstance.get(`/users/${username}/weekly-summary`),
  summary: username => axiosInstance.get(`/users/${username}/summary`),
  getDateSummary: (username, type, startDate, endDate, timezone = '+0000') =>
    axiosInstance.get(
      `/users/${username}/summary?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&type=${type}&timezone=${timezone}`
    ),
  getGameDateSummary: (
    username,
    gameId,
    type,
    startDate,
    endDate,
    timezone = '+0000'
  ) =>
    axiosInstance.get(
      `/users/${username}/summary?gameId=${gameId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&type=${type}&timezone=${timezone}`
    ),
  getTopGames: (username, startDate, endDate) =>
    axiosInstance.get(
      `/users/${username}/topTen?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ),
  getStatistics: (username, startDate, endDate) =>
    axiosInstance.get(
      `/users/${username}/statistics?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ),
  getGameStatistics: (username, gameId, startDate, endDate) =>
    axiosInstance.get(
      `/users/${username}/statistics?gameId=${gameId}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ),
  getRankings: () => axiosInstance.get(`/users/rankings`),
  saveCommunicationSettings: (id, settings) =>
    axiosInstance.patch(`/users/${id}/options/communication`, settings),
};

export const games = {
  getGame: id => axiosInstance.get(`/games/${id}`),
  list: (params = {}) =>
    axiosInstance.get(`/games`, {
      params: {
        skip: params?.skip,
        limit: params?.limit,
        genres: params?.genres,
        name: params?.name,
      },
    }),
  getWeeklySummary: id => axiosInstance.get(`/games/${id}/weekly-summary`),
  getDateSummary: (id, type, startDate, endDate, timezone = '+0000') =>
    axiosInstance.get(
      `/games/${id}/summary?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&type=${type}&timezone=${timezone}`
    ),
  getDetailedSummary: (id, startDate, endDate) => {
    let queryString = '';

    if (startDate && !endDate) {
      queryString = `?startDate=${startDate.toISOString()}`;
    } else if (!startDate && endDate) {
      queryString = `?endDate=${endDate.toISOString()}`;
    } else if (startDate && endDate) {
      queryString = `?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }

    return axiosInstance.get(`/games/${id}/detailed-summary${queryString}`);
  },
  getStatistics: (id, startDate, endDate) =>
    axiosInstance.get(
      `/games/${id}/statistics?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ),
  getTopPlayers: (id, startDate, endDate) =>
    axiosInstance.get(
      `/games/${id}/top_players?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
    ),
  getRankings: () => axiosInstance.get(`/games/rankings`),
  getGenres: () => axiosInstance.get(`/games/genres`),
};

export const sessions = {
  get: id => axiosInstance.get(`/sessions/${id}`),
  setComments: (id, comments) =>
    axiosInstance.put(`/sessions/${id}/comments`, { comments: comments }),
  setPrivacyMode: (id, isPrivate) =>
    axiosInstance.put(`/sessions/${id}/private/${isPrivate}`),
  getSessionsForUser: (username, skip = 0, limit = 20) =>
    axiosInstance.get(`/sessions?user=${username}&skip=${skip}&limit=${limit}`),
  getSessionsForGame: (gameId, skip = 0, limit = 20) =>
    axiosInstance.get(`/sessions?gameId=${gameId}&skip=${skip}&limit=${limit}`),
  getSessionsForUserGame: (username, gameId, skip = 0, limit = 20) =>
    axiosInstance.get(
      `/sessions?user=${username}&gameId=${gameId}&skip=${skip}&limit=${limit}`
    ),
};

export const search = {
  basic: name => axiosInstance.post(`/search/simple`, { name: name }),
};

export const topTen = {
  get: dateRange => axiosInstance.get(`/sessions/top10/${dateRange}`),
};

export const screenshots = {
  getGame: (gameId, skip = 0, limit = 20) =>
    axiosInstance.get(
      `/screenshots?game=${gameId}&skip=${skip}&limit=${limit}`
    ),
  getUser: (username, skip = 0, limit = 20) =>
    axiosInstance.get(
      `/screenshots?user=${username}&skip=${skip}&limit=${limit}`
    ),
  getUserGame: (username, gameId, skip = 0, limit = 20) =>
    axiosInstance.get(
      `/screenshots?user=${username}&game=${gameId}&skip=${skip}&limit=${limit}`
    ),
};

export const everquest2 = {
  getWorlds: () => axiosInstance.get(`/everquest2/worlds`),
  getCharacter: (name, world) =>
    axiosInstance.get(
      `/everquest2/character?name=${encodeURIComponent(
        name
      )}&world=${encodeURIComponent(world)}`
    ),
  add: (world, name) =>
    axiosInstance.put(
      `/everquest2/character?world=${encodeURIComponent(
        world
      )}&name=${encodeURIComponent(name)}`
    ),
  delete: id => axiosInstance.delete(`/everquest2/character/${id}`),
};

export const warcraft = {
  getRealms: () => axiosInstance.get(`/warcraft/realms`),
  getCharacter: (name, realm, region = 'us') =>
    axiosInstance.get(
      `/warcraft/character?name=${encodeURIComponent(
        name
      )}&realm=${encodeURIComponent(realm)}&region=${region}`
    ),
  add: (name, realm, region = 'us') =>
    axiosInstance.put(
      `/warcraft/character?name=${encodeURIComponent(
        name
      )}&realm=${encodeURIComponent(realm)}&region=${region}`
    ),
  delete: id => axiosInstance.delete(`/warcraft/character/${id}`),
};
