import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, List } from 'semantic-ui-react';

export default class GeneralAchievementListItem extends PureComponent {

	static propTypes = {
		achievement: PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.string,
        })
	};

	static defaultProps = {
		achievement: null
	};

	render() {
		const { achievement } = this.props;

        if(!achievement) {
            return null;
        }

		return (
            <List.Item key={achievement.id}>
                <Image rounded size="mini" src={achievement.icon} />
                <List.Content>
                    <List.Header>{achievement.title}</List.Header>
                    <List.Description>{achievement.description}</List.Description>
                </List.Content>
            </List.Item>
		);
	}
}
