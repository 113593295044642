import React from 'react';
import { Dimmer, Loader, Header, Button } from 'semantic-ui-react';

const PrivacySegment = ({
  isPrivate,
  isEditable,
  isLoading,
  onPrivacyModeChanged = () => {},
}) => {
  if (!isEditable) {
    return null;
  }

  const buttonProps = isPrivate
    ? { icon: 'privacy', content: 'Private' }
    : { icon: 'eye', content: 'Public' };

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Header
        as='h4'
        dividing
      >
        Privacy
      </Header>
      <Button
        {...buttonProps}
        toggle
        active={isPrivate}
        onClick={(e, d) => onPrivacyModeChanged(!d.active)}
      />
    </>
  );
};

export default PrivacySegment;
