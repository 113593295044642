import { useState, useEffect, useReducer } from 'react';
import { screenshots as screenshotsApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducerPaged,
} from '../reducers/apiReducerPaged';

export const useScreenshotApi = (initialUser, initialGame) => {
  const [gameId] = useState(initialGame);
  const [username] = useState(initialUser);
  const [skip, setSkip] = useState(0);
  const [limit] = useState(100);

  const [state, dispatch] = useReducer(apiReducerPaged, {
    isLoading: false,
    error: null,
    data: null,
    pagination: {
      page: 0,
      limit: 50,
    },
  });

  const loadMore = () => {
    setSkip(skip + limit);
  };

  useEffect(() => {
    const performFetch = async (username, gameId, skip, limit) => {
      try {
        dispatch({ type: FETCH });

        const response =
          gameId && username
            ? await screenshotsApi.getUserGame(username, gameId, skip, limit)
            : username
            ? await screenshotsApi.getUser(username, skip, limit)
            : await screenshotsApi.getGame(gameId, skip, limit);

        const pagination = {
          page: response.data.currentPage,
          pages: response.data.totalPages,
          limit: limit,
          total: response.data.total,
        };

        dispatch({
          type: FETCH_SUCCESS,
          payload: response.data.screenshots,
          pagination: pagination,
        });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch(username, gameId, skip, limit);
  }, [gameId, username, skip, limit]);

  return [state, loadMore];
};
