import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, Button, Icon } from 'semantic-ui-react';

export default class GameHistoryMenu extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            view: this.props.view,
        };
    }

    onViewClick = (view) => {
        this.setState( () => ( { 'view': view } ) );
        this.props.onViewTypeClick(view);
    }

    render() {

        const {view} = this.state;

        return (
            <Menu borderless attached="top">
                <Menu.Item position="right">
                    <Button.Group>
                        <Button icon active={view === 'cards'} onClick={() => this.onViewClick('cards')}>
                            <Icon name="clone"/>
                        </Button>
                        <Button icon active={view === 'list'} onClick={() => this.onViewClick('list')}>
                            <Icon name='list' />
                        </Button>
                    </Button.Group>
                </Menu.Item>
            </Menu>
        );
    }
}

GameHistoryMenu.propTypes = {
    view: PropTypes.string,
    onViewTypeClick: PropTypes.func,
};

GameHistoryMenu.defaultProps = {
    view: 'cards',
    onViewTypeClick: () => {},
}