import React, {PureComponent} from 'react';
import {sortBy} from 'lodash';
import { Segment, Header, List } from 'semantic-ui-react';
import { toPrettyDaysHoursMinutesRounded } from '../../../dateTimeFormatting';

export default class HourOfDayToolip extends PureComponent {

    static hours = ['12A', '1A', '2A', '3A', '4A', '5A', '6A', '7A', '8A', '9A', '10A', '11A', '12P', '1P', '2P', '3P', '4P', '5P', '6P', '7P', '8P', '9P', '10P', '11P'];

    render() {
        const { payload } = this.props;

        if(!payload || payload.length === 0) {
            return null;
        }

        // Pull out the others detail
        const othersDetail = payload[0].payload["OthersTooltip"] || [];

        // Concat the top 5 and others arrays together
        const sessions = [...payload.filter(p => p.name !== 'Others'), ...othersDetail];

        // Sort 'em by duration descending
        const sortedPayload = sortBy(sessions, p => -p.value);

        return (
            <Segment>
                <List>
                    {
                        sortedPayload.map( p => (
                            <List.Item key={p.name}>
                                <List.Icon name="square" style={{color: p.fill}} />
                                <List.Content>
                                    <List.Header>{p.name}</List.Header>
                                    <List.Description>{toPrettyDaysHoursMinutesRounded(p.value)}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))
                    }
                </List>
                <Header as="h5">
                    <Header.Content>
                        {HourOfDayToolip.hours[payload[0].payload.hour]}
                    </Header.Content>
                </Header>
            </Segment>
        )
        // const { payload, dataSet } = this.props;

        // if(!payload || payload.length === 0) {
        //     return null;
        // }

        // const data = payload[0].payload;

        // return (
        //     <Segment>
        //         <Header as="h4">
        //             <Header.Content>
        //                 {toPrettyDaysHoursMinutesRounded(data[dataSet])}
        //                 <Header.Subheader>
        //                     {HourOfDayToolip.hours[data.hour]}
        //                 </Header.Subheader>
        //             </Header.Content>
        //         </Header>
        //     </Segment>
        // )
    }
}