import { cloneDeep, extend } from 'lodash';

export default class User {
  constructor(user) {
    this.publicStats = {
      gameHistory: [],
    };

    extend(this, user);
  }

  get stats() {
    return this.personalStats || this.publicStats || {};
  }

  get activeTime() {
    return this.stats.gameHistory.reduce((value, gh) => {
      return value + gh.totalActiveTime;
    }, 0);
  }

  get idleTime() {
    return this.stats.gameHistory.reduce((value, gh) => {
      return value + gh.totalIdleTime;
    }, 0);
  }

  get latestSession() {
    return this.stats.latestSession;
  }

  get history() {
    return this.stats.gameHistory.sort((a, b) => a.name.localeCompare(b.name));
  }

  get topGames() {
    return this.stats.gameHistory
      .sort((a, b) => b.totalActiveTime - a.totalActiveTime)
      .slice(0, 6)
      .map(g => ({
        _id: g._id,
        boxart: g.boxart,
        name: g.name,
      }));
  }

  clone() {
    return cloneDeep(this);
  }
}
