import React, { Fragment } from 'react';
import { Grid, Header, Segment, Statistic } from 'semantic-ui-react';
import { toPrettyDaysHoursMinutesRounded } from '../dateTimeFormatting';
import NoPlayTimePlaceholder from '../NoPlayTimePlaceholder';

const StatisticsSegment = ({ totalTime = 0, totalSessions = 0 }) => {
  if (totalSessions === 0) {
    return (
      <Fragment>
        <Header
          attached='top'
          block
        >
          Statistics
        </Header>
        <NoPlayTimePlaceholder attached='bottom' />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Header
        attached='top'
        block
      >
        Statistics
      </Header>
      <Segment attached='bottom'>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Statistic size='mini'>
                <Statistic.Value>
                  {toPrettyDaysHoursMinutesRounded(totalTime)}
                </Statistic.Value>
                <Statistic.Label>Total Time</Statistic.Label>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Statistic size='mini'>
                <Statistic.Value>{totalSessions}</Statistic.Value>
                <Statistic.Label>Total Sessions</Statistic.Label>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Statistic size='mini'>
                <Statistic.Value>
                  {toPrettyDaysHoursMinutesRounded(totalTime / totalSessions)}
                </Statistic.Value>
                <Statistic.Label>Average Session</Statistic.Label>
              </Statistic>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Fragment>
  );
};

export default StatisticsSegment;
