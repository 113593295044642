import { useState, useEffect, useReducer } from 'react';
import { users as userApi, games as gameApi } from '../../api';
import { FETCH, FETCH_ERROR, FETCH_SUCCESS } from '../reducers/apiReducer';
import DateSummaryModel from '..//charts/bar/date/DateSummaryModel';
import DayOfWeekSummaryModel from '../charts/bar/day-of-week/DayOfWeekSummaryModel';
import HourOfDaySummaryModel from '../charts/bar/hour-of-day/HourOfDaySummaryModel';

const reducer = (state, action) => {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case FETCH_SUCCESS:
      let model = null;
      switch (action.payload.type) {
        case 'date':
          model = new DateSummaryModel(action.payload.summary);
          break;
        case 'dayOfWeek':
          model = new DayOfWeekSummaryModel(action.payload.summary);
          break;
        case 'hour':
          model = new HourOfDaySummaryModel(action.payload.summary);
          break;
        default:
          break;
      }
      return {
        ...state,
        data: model,
        isLoading: false,
        error: null,
      };
    case FETCH_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      throw new Error();
  }
};
export const useVisualizationData = (
  initialUsername,
  initialGameId,
  initialType = 'date',
  initialStartDate,
  initialEndDate,
  timezone
) => {
  const [username] = useState(initialUsername);
  const [gameId] = useState(initialGameId);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [type, setType] = useState(initialType);

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const fetch = async (user, gameId, type, startDate, endDate, timezone) => {
      try {
        dispatch({ type: FETCH });
        const response =
          gameId && user
            ? await userApi.getGameDateSummary(
                user,
                gameId,
                type,
                startDate,
                endDate,
                timezone
              )
            : user
            ? await userApi.getDateSummary(
                user,
                type,
                startDate,
                endDate,
                timezone
              )
            : await gameApi.getDateSummary(
                gameId,
                type,
                startDate,
                endDate,
                timezone
              );

        dispatch({
          type: FETCH_SUCCESS,
          payload: { summary: response.data, type: type },
        });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    fetch(username, gameId, type, startDate, endDate, timezone);
  }, [username, gameId, type, startDate, endDate, timezone]);

  return [state, setType, setStartDate, setEndDate];
};
