import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Button, Image, Icon } from 'semantic-ui-react';
import memoize from 'memoize-one';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

export default class Gallery extends PureComponent {
    static propTypes = {
        screenshots: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string.isRequired,
            thumb_url: PropTypes.string.isRequired
        })),
        showAll: PropTypes.bool,
    };

    static defaultProps = {
        screenshots: [],
        showAll: false,
    }

    images = memoize(
        (screenshots) => screenshots.map(s => s.url)
    );

    thumbnails = memoize(
        (screenshots, isExpanded) => isExpanded ? screenshots : screenshots.slice(0, 8)
    );

    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
            isExpanded: this.props.showAll,
        };
    }

    toggleLightbox = (index) => {
        this.setState( () => {
            return {
                isOpen: true,
                photoIndex: index,
            }
        })
    }

    viewAll = () => {
        this.setState( () => ({isExpanded: true}));
    }

    collapse = () => {
        this.setState( () => ({isExpanded: false}));
    }

    render() {
        const { screenshots, showAll } = this.props;
        const { photoIndex, isOpen, isExpanded } = this.state;

        const images = this.images(screenshots);
        const thumbs = this.thumbnails(screenshots, isExpanded);

        const expandButton = !showAll && !isExpanded && images.length > 9 ?
                            <Button icon labelPosition='left' size="tiny" onClick={() => this.viewAll()}>
                                <Icon name='angle double down' />
                                View All
                            </Button> : null;

        const collapseButton = !showAll && isExpanded ?
                                <Button icon labelPosition='left' size="tiny" onClick={() => this.collapse()}>
                                    <Icon name='angle double up' />
                                    Collapse
                                </Button> : null;
        return (
            <Fragment>

                <Image.Group>
                    {
                        thumbs.map( (s,index) => {
                            return <Image
                                        key={s.id}
                                        label={s.isPrivate ? {corner: 'left', icon: 'privacy', size: "tiny"} : null}
                                        src={s.thumb_url}
                                        style={{'cursor': 'pointer'}}
                                        onClick={() => this.toggleLightbox(index)}
                                    />
                        })
                    }
                </Image.Group>

                {expandButton}
                {collapseButton}

                {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                    })
                    }
                    onMoveNextRequest={() =>
                    this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                    })
                    }
                />
                )}

            </Fragment>
        )
    }
}