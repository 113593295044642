import React from 'react'
import { Card, Placeholder } from 'semantic-ui-react';
import ImageWithFallback from '../../images/ImageWithFallback';

const SimpleGameCard = ({id, name, boxart, version = '', onClick=() => {}, isLoading = false}) => {

    const description = version && version !== '' ? <Card.Description>{version}</Card.Description> : null;

    const image = isLoading ?
                    <Placeholder><Placeholder.Image square /></Placeholder>:
                    <ImageWithFallback size="medium" src={boxart} fallback="/images/game.png" />;

    const content = isLoading ?
                        <Placeholder>
                            <Placeholder.Header>
                                <Placeholder.Line length="medium" />
                            </Placeholder.Header>
                        </Placeholder> :
                        <Card.Content>
                            <Card.Header>{name}</Card.Header>
                            {description}
                        </Card.Content>;

    return (
        <Card onClick={()=>onClick(id)}>
            {image}
            {content}
        </Card>
    )
}

export default SimpleGameCard;