import PropTypes from 'prop-types';

export default {
    _id: PropTypes.string,
    game: PropTypes.shape({
        boxart: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    user: PropTypes.shape({
        avatar: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
    }),
    screenshot: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        thumb_url: PropTypes.string.isRequired,
    }),
    created: PropTypes.string.isRequired,
    isPrivate: PropTypes.bool,
    sessionId: PropTypes.object,
}