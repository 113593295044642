import React from 'react';
import {Segment, Header} from 'semantic-ui-react';
import ImageWithFallback from '../../images/ImageWithFallback';

const CatalogGameCard = ({id, name, boxart, onClick = () => {}}) => {
    return (
        <Segment  onClick={() => onClick(id)} style={{'cursor': 'pointer', 'height': '100%'}} compact>
            <Header as="h5" dividing content={name} />
            <ImageWithFallback src={boxart} fallback="/images/game.png" alt={name} size="medium" centered />
        </Segment>
    )
}

export default CatalogGameCard;