import React, { useMemo } from 'react';
import { Label, Icon } from 'semantic-ui-react';

const PlatformLabel = ({ platform = 'Windows' }) => {
  const iconName = useMemo(() => {
    switch (platform) {
      case 'OSX':
        return 'apple';
      case 'Windows':
        return 'windows';
      case 'Linux':
        return 'linux';
      default:
        return 'question circle';
    }
  }, [platform]);

  return (
    <Label>
      <Icon name={iconName} />
      {platform}
    </Label>
  );
};

export default PlatformLabel;
