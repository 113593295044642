import React from 'react';
import { Route, Routes } from 'react-router';

import GamesIndexContainer from './index/GamesIndexContainer';
import GamesDetailContainer from './detail/GamesDetailContainer';

const GamesContainer = () => (
  <Routes>
    <Route
      exact
      path='/'
      element={<GamesIndexContainer />}
    />
    <Route
      exact
      path='/:id'
      element={<GamesDetailContainer />}
    />
  </Routes>
);

export default GamesContainer;
