import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Header,
  Container,
  Dimmer,
  Loader,
  Form,
  Image,
  Segment,
  Label,
  Button,
} from 'semantic-ui-react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import {
  navigateToProviderConnection,
  navigateToProviderDisconnect,
  userRoute,
} from '../../../store/siteNavigation';

import EverquestTwoComponent from './components/EverquestTwoComponent';
import WarcraftComponent from './components/WarcraftComponent';
import ConnectionList from './components/ConnectionListItem';
import CommunicationComponent from './components/CommunicationComponent';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from '../common/hooks/useUserProfile';
import { users as userApi } from '../../../api';

const connections = [
  {
    image: '/images/steam.svg',
    name: 'Steam',
    provider: 'steam',
    description:
      'Connect to steam to quickly add new games from your steam collection',
  },
  {
    image: '/images/twitch.svg',
    name: 'Twitch',
    provider: 'twitchtv',
    description:
      'Connect your Twitch.tv account to automatically link recorded streams to sessions',
  },
  {
    image: '/images/discord.svg',
    name: 'Discord',
    provider: 'discord',
    description: 'Connect your Discord account',
  },
  {
    image: '/images/battlenet.svg',
    name: 'Battle.net',
    provider: 'bnet',
    description:
      'Connect your Battle.Net account to automatically link your World of Warcraft achievements to sessions',
  },
  {
    image: '/images/twitter.svg',
    name: 'Twitter',
    provider: 'twitter',
    description: 'Connect your Twitter account',
  },
  {
    image: '/images/facebook.svg',
    name: 'Facebook',
    provider: 'facebook',
    description: 'Connect your Facebook account',
  },
  {
    image: '/images/google.svg',
    name: 'Google',
    provider: 'google',
    description: 'Connect your Google account',
  },
];

const UserEditContainer = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [{ data: userProfile, isLoading: isUserLoading }] =
    useUserProfile(username);
  const [user, setUser] = useState(userProfile);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setUser(userProfile);
  }, [userProfile]);

  const onCancelClick = useCallback(
    () => navigate(userRoute(username)),
    [navigate]
  );

  const onToggleProvider = useCallback((provider, isChecked) => {
    if (isChecked) {
      navigateToProviderConnection(provider, `/users/${username}/edit`);
    } else {
      navigateToProviderDisconnect(provider, `/users/${username}/edit`);
    }
  });

  const onToggleCommunicationOptions = useCallback(
    async settings => {
      try {
        setIsUpdating(true);

        const response = await userApi.saveCommunicationSettings(
          user._id,
          settings
        );

        setUser(response.data);
      } finally {
        setIsUpdating(false);
      }
    },
    [user]
  );

  const accounts = useMemo(() => user?.accounts || [], [user]);
  const options = useMemo(() => user?.options?.communication || null, [user]);

  const communicationSettings = !useFeatureIsOn('email-summaries') ? null : (
    <Segment>
      <Label attached='top'>Communication Settings</Label>
      <CommunicationComponent
        options={options}
        isUpdating={isUpdating}
        onToggleEmailSummaries={onToggleCommunicationOptions}
      />
    </Segment>
  );

  return (
    <Container>
      <Dimmer active={isUserLoading}>
        <Loader />
      </Dimmer>
      <Header
        as='h2'
        dividing
      >
        <Image
          src={user?.avatar}
          rounded
          spaced='right'
        />
        {user?.username}
        <Button floated='right'>Save</Button>
        <Button
          floated='right'
          onClick={onCancelClick}
        >
          Cancel
        </Button>
      </Header>
      <Segment>
        <Label attached='top'>Profile</Label>
        <Form>
          <Form.Input
            label='E-Mail'
            type='email'
            value={user?.email}
          />
          <Form.Input
            label='Avatar'
            type='url'
            value={user?.avatar}
          />
        </Form>
      </Segment>
      {communicationSettings}
      <Segment>
        <Label attached='top'>Connections</Label>
        <ConnectionList
          connections={connections}
          accounts={accounts}
          onToggle={onToggleProvider}
        />
      </Segment>
      <Segment>
        <Label attached='top'>World of Warcraft Characters</Label>
        {user && (
          <WarcraftComponent
            username={user.username}
            characters={user.warcraftCharacters}
          />
        )}
      </Segment>
      <Segment>
        <Label attached='top'>Everquest 2 Characters</Label>
        {user && (
          <EverquestTwoComponent
            username={user.username}
            characters={user.everquest2Characters}
            onCharacterAdded={character => addEverquest2Character(character)}
            onRemoveCharacter={character =>
              removeEverquest2Character(character.id)
            }
          />
        )}
      </Segment>
    </Container>
  );
};

export default UserEditContainer;
