import React, {
  PureComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { authentication as authApi } from '../../../api';
import queryString from 'query-string';
import { Container, Dimmer, Loader } from 'semantic-ui-react';
import RegistrationSuccessComponent from '../components/RegistrationSuccessComponent';
import RegistrationForm from '../components/RegistrationForm';
import { cloneDeep } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  homeRoute,
  userEditRoute,
  loginRoute,
} from '../../../store/siteNavigation';

const RegistrationComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [userNameError, setUserNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [avatarError, setAvatarError] = useState('');
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  // Get the response value from the query string
  const parsed = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );

  useEffect(() => {
    // Either there's not query string, or it doesn't contain a response entry
    if (!parsed || !parsed.login) {
      navigate(loginRoute);
      return;
    }

    const loginResponse = JSON.parse(atob(parsed.login));
    const redirectTo = parsed.redirectTo;

    // The login response isn't valid, so bounce the user back home
    if (!loginResponse || !loginResponse.user) {
      navigate(loginRoute);
      return;
    }

    setUser(loginResponse.user);
    setRedirectUrl(redirectTo);
  }, [parsed, navigate]);

  const onNavigateToHome = useCallback(() => navigate(homeRoute), [navigate]);
  const onNavigateToEdit = useCallback(
    () => navigate(userEditRoute(user.username)),
    [navigate, user]
  );

  const onRegister = useCallback(
    (username, email, avatar) => {
      const newUser = cloneDeep(user);

      newUser.username = username;
      newUser.email = email;
      newUser.avatar = avatar;

      setIsSaving(true);
      setUserNameError('');
      setEmailError('');

      authApi
        .register(newUser)
        .then(response => {
          // If a redirect URL is set, send the user to that URL
          if (redirectUrl) {
            window.location = `${redirectUrl}?login=${btoa(
              JSON.stringify({ user: response.data })
            )}`;
          } else {
            // Set the user
            setUser(response.data);
            setRegistrationSuccess(true);
          }
        })
        .catch(e => {
          const errors = e.response.data;

          const usernameErrors = errors
            .filter(x => x.field === 'username')
            .map(x => x.message)
            .join(' and ');
          const emailErrors = errors
            .filter(x => x.field === 'email')
            .map(x => x.message)
            .join(' and ');

          setUserNameError(usernameErrors);
          setEmailError(emailErrors);

          console.error(e);
        })
        .finally(() => {
          setIsSaving(false);
        });
    },
    [user, authApi, redirectUrl]
  );

  return (
    <Container>
      <Dimmer active={isSaving}>
        <Loader />
      </Dimmer>
      <RegistrationSuccessComponent
        visible={registrationSuccess}
        onEditClick={onNavigateToEdit}
        onHomeClick={onNavigateToHome}
      />
      <RegistrationForm
        visible={!registrationSuccess}
        username={user?.username}
        email={user?.email}
        avatar={user?.avatar}
        usernameError={userNameError}
        emailError={emailError}
        onRegister={onRegister}
        onCancel={onNavigateToHome}
      />
    </Container>
  );
};

export default RegistrationComponent;
