import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const NoPlayTimePlaceholder = ({attached}) => (
        <Segment placeholder attached={attached}>
            <Header icon>
                <Icon name='calendar times outline' />
                No playtime has been recorded
            </Header>
        </Segment>
);

export default NoPlayTimePlaceholder;