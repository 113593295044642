import React, { useCallback, useState, useEffect } from 'react';
import { Checkbox, Dimmer, List, ListItem, Loader } from 'semantic-ui-react';

const CommunicationComponent = ({
  options = null,
  isUpdating = false,
  onToggleEmailSummaries = () => {},
}) => {
  const [receiveSummaryEmails, setReceiveSummaryEmails] = useState(
    options?.emailSummaries
  );

  useEffect(() => {
    setReceiveSummaryEmails(options?.receiveSummaryEmails);
  }, [options]);

  const onToggleClicked = useCallback(
    (e, d) => onToggleEmailSummaries?.({ receiveSummaryEmails: d.checked }),
    [onToggleEmailSummaries]
  );

  return (
    <div>
      <Dimmer>
        <Loader active={isUpdating} />
      </Dimmer>
      <List
        divided
        relaxed
      >
        <ListItem>
          <List.Content>
            <List.Content floated='right'>
              <Checkbox
                toggle
                checked={receiveSummaryEmails}
                onChange={onToggleClicked}
              />
            </List.Content>
            <List.Content>
              <List.Header>Receive email notifications</List.Header>
              <List.Description>
                Toggle this on to receive end-of-month and end-of-year gameplay
                summaries
              </List.Description>
            </List.Content>
          </List.Content>
        </ListItem>
      </List>
    </div>
  );
};

export default CommunicationComponent;
