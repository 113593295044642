import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import {Segment, Header, Icon, Button} from 'semantic-ui-react';

export default class RegistrationSuccessComponent extends PureComponent {

    static propTypes = {
        visible: PropTypes.bool,
        onEditClick: PropTypes.func,
        onHomeClick: PropTypes.func,
    };

    static defaultProps = {
        visible: false,
        onEditClick: () => {},
        onHomeClick: () => {},
    };

    render() {

        const {visible, onEditClick, onHomeClick} = this.props;

        if(!visible) {
            return null;
        }

        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name='trophy' />
                    Congratulations!  You've successfully registered with TempusGameIt!
                </Header>
                <Segment.Inline>
                    <Button primary onClick={onHomeClick}>Get Started</Button>
                    <Button onClick={onEditClick}>Edit Profile</Button>
                </Segment.Inline>
            </Segment>
        )
    }
}