import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Menu, Input, Dropdown } from 'semantic-ui-react';
import moment from 'moment';

export default class ChartDateRangeMenu extends PureComponent {
  static propTypes = {
    activeItem: PropTypes.string,
    onSearch: PropTypes.func,
  };

  static defaultProps = {
    activeItem: 'week',
    startDate: '',
    endDate: '',
    onSearch: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      activeItem: props.activeItem,
      startInput: props.startDate,
      endInput: props.endDate,
      startError: false,
      endError: false,
    };
  }

  onItemClick = name => {
    let startDate = moment().startOf('day');
    let endDate = moment().endOf('day');

    switch (name) {
      case 'week':
        startDate = endDate.clone().startOf('day').subtract(7, 'days');
        break;
      case 'month':
        startDate = endDate.clone().startOf('day').subtract(1, 'month');
        break;
      case 'quarter':
        startDate = endDate.clone().startOf('day').subtract(1, 'quarter');
        break;
      case 'year':
        startDate = endDate.clone().startOf('day').subtract(1, 'year');
        break;
      case 'qtd':
        startDate = endDate.clone().startOf('day').startOf('quarter');
        break;
      case 'ytd':
        startDate = endDate.clone().startOf('day').startOf('year');
        break;
      case 'all':
        startDate = endDate.clone().startOf('day').subtract(7, 'years');
        break;
      default:
        break;
    }

    // Raise the event
    this.props.onSearch(startDate.toDate(), endDate.toDate());

    this.setState(() => ({ activeItem: name }));
  };

  onDateSearchClick = () => {
    const startDate = new moment(this.state.startInput).startOf('day');
    const endDate = new moment(this.state.endInput).endOf('day');

    let endError = false;
    let startError = false;

    if (this.state.startInput === '' && this.state.endInput !== '') {
      startError = true;
    } else if (this.state.startInput !== '' && this.state.endInput === '') {
      endError = true;
    } else if (this.state.startInput === '' && this.state.endInput === '') {
      startError = true;
      endError = true;
    } else {
      endError = endDate.isBefore(startDate);
      startError = startDate.isAfter(endDate);
    }

    if (startError || endError) {
      this.setState(() => ({
        startError: startError,
        endError: endError,
      }));

      return;
    }

    this.setState(() => ({
      startError: false,
      endError: false,
      activeItem: '',
    }));

    this.props.onSearch(startDate.toDate(), endDate.toDate());
  };

  componentDidUpdate(prevProps) {
    let newState = {};

    if (prevProps.startDate !== this.props.startDate) {
      newState.startInput = this.props.startDate;
      newState.startError = false;
    }

    if (prevProps.endDate !== this.props.endDate) {
      newState.endInput = this.props.endDate;
      newState.endError = false;
    }

    this.setState(() => newState);
  }

  onStartInputChanged = (e, d) => {
    this.setState(() => ({ startInput: d.value }));
  };

  onEndInputChanged = (e, d) => {
    this.setState(() => ({ endInput: d.value }));
  };

  render() {
    const { activeItem, startInput, endInput, startError, endError } =
      this.state;

    return (
      <Menu
        stackable
        compact
        size='small'
      >
        <Menu.Item
          name='week'
          onClick={() => this.onItemClick('week')}
          active={activeItem === 'week'}
        >
          last week
        </Menu.Item>
        <Menu.Item
          name='month'
          onClick={() => this.onItemClick('month')}
          active={activeItem === 'month'}
        >
          last month
        </Menu.Item>
        <Menu.Item
          name='quarter'
          onClick={() => this.onItemClick('quarter')}
          active={activeItem === 'quarter'}
        >
          last quarter
        </Menu.Item>
        <Menu.Item
          name='year'
          onClick={() => this.onItemClick('year')}
          active={activeItem === 'year'}
        >
          last year
        </Menu.Item>
        <Dropdown
          item
          text='More'
        >
          <Dropdown.Menu>
            <Dropdown.Item
              name='qtd'
              onClick={() => this.onItemClick('qtd')}
              active={activeItem === 'qtd'}
            >
              qtd
            </Dropdown.Item>
            <Dropdown.Item
              name='ytd'
              onClick={() => this.onItemClick('ytd')}
              active={activeItem === 'ytd'}
            >
              ytd
            </Dropdown.Item>
            <Dropdown.Item
              name='all'
              onClick={() => this.onItemClick('all')}
              active={activeItem === 'all'}
            >
              all time
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item position='right'>
          <Input
            type='date'
            value={startInput}
            error={startError}
            onChange={this.onStartInputChanged}
          />
          &nbsp;to&nbsp;
          <Input
            type='date'
            value={endInput}
            error={endError}
            onChange={this.onEndInputChanged}
            action={{
              icon: 'filter',
              onClick: () => {
                this.onDateSearchClick();
              },
            }}
          />
        </Menu.Item>
      </Menu>
    );
  }
}
