import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Segment, Dimmer, Loader } from 'semantic-ui-react';

import DateBarChart from './bar/date/DateBarChart';
import DayOfWeekBarChart from './bar/day-of-week/DayOfWeekBarChart';
import HourOfDayBarChart from './bar/hour-of-day/HourOfDayBarChart';

import DateSummaryModel from './bar/date/DateSummaryModel';
import DayOfWeekSummaryModel from './bar/day-of-week/DayOfWeekSummaryModel';
import HourOfDaySummaryModel from './bar/hour-of-day/HourOfDaySummaryModel';

export default class VisualizationComponent extends PureComponent {
  static propTypes = {
    chartModel: PropTypes.oneOfType([
      PropTypes.instanceOf(DateSummaryModel),
      PropTypes.instanceOf(DayOfWeekSummaryModel),
      PropTypes.instanceOf(HourOfDaySummaryModel),
    ]),
    isLoading: PropTypes.bool,
    onChartChanged: PropTypes.func,
    onDateChanged: PropTypes.func,
  };

  static defaultProps = {
    chartModel: null,
    isLoading: false,
    onChartChanged: () => {},
    onDateChanged: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedChart: 'date',
      selectedMath: 'gross',
    };
  }

  flipChart = chartName => {
    this.setState(() => ({ selectedChart: chartName }));

    this.props.onChartChanged(chartName);
  };

  setAverageOrGross = type => {
    this.setState(() => ({ selectedMath: type }));
  };

  render() {
    const { selectedChart, selectedMath } = this.state;
    const { chartModel, isLoading } = this.props;

    let chart = null;

    switch (selectedChart) {
      case 'date':
        chart = (
          <DateBarChart
            model={chartModel}
            dataSet={selectedMath}
            onBarClick={this.props.onDateChanged}
          />
        );
        break;
      case 'dayOfWeek':
        chart = (
          <DayOfWeekBarChart
            model={chartModel}
            dataSet={selectedMath}
          />
        );
        break;
      case 'hour':
        chart = (
          <HourOfDayBarChart
            model={chartModel}
            dataSet={selectedMath}
          />
        );
        break;
      default:
        break;
    }

    return (
      <>
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Segment.Group>
          <Segment basic>
            <Button.Group
              icon
              size='small'
              style={{ marginRight: '10px' }}
            >
              <Button
                title='By Date'
                active={selectedChart === 'date'}
                onClick={() => this.flipChart('date')}
              >
                <Icon name='calendar outline' />
              </Button>
              <Button
                title='By Day of Week'
                active={selectedChart === 'dayOfWeek'}
                onClick={() => this.flipChart('dayOfWeek')}
              >
                <Icon name='content' />
              </Button>
              <Button
                title='By Hour of Day'
                active={selectedChart === 'hour'}
                onClick={() => this.flipChart('hour')}
              >
                <Icon name='clock outline' />
              </Button>
            </Button.Group>
            <Button.Group
              icon
              size='small'
            >
              <Button
                title='Total time'
                active={selectedMath === 'gross'}
                onClick={() => this.setAverageOrGross('gross')}
              >
                total
              </Button>
              <Button
                title='Average time'
                active={selectedMath === 'average'}
                onClick={() => this.setAverageOrGross('average')}
              >
                avg
              </Button>
            </Button.Group>
          </Segment>
          <Segment basic>{chart}</Segment>
        </Segment.Group>
      </>
    );
  }
}
