import React, { useMemo } from 'react';
import { List, Image, Button, Icon } from 'semantic-ui-react';

const ConnectionList = ({
  connections = [],
  accounts = [],
  onToggle = () => {},
}) => {
  const isDisconnectAllowed = useMemo(() => {
    return accounts.length > 1;
  }, [accounts]);

  const connectionsModel = useMemo(
    () =>
      connections.map(c => ({
        image: c.image,
        name: c.name,
        provider: c.provider,
        description: c.description,
        account: accounts.find(u => u.provider === c.provider),
      })),
    [connections, accounts]
  );

  return (
    <List
      divided
      verticalAlign='middle'
      relaxed
    >
      {connectionsModel.map(c => (
        <ConnectionListItem
          image={c.image}
          name={c.name}
          provider={c.provider}
          description={c.description}
          isConnected={c.account}
          allowDisconnect={isDisconnectAllowed}
          onToggle={onToggle}
          key={c.provider}
        />
      ))}
    </List>
  );
};
const ConnectionListItem = ({
  image,
  name,
  provider,
  description,
  isConnected,
  allowDisconnect = false,
  onToggle = () => {},
}) => {
  const button = isConnected ? (
    <Button
      icon
      disabled={!allowDisconnect}
      onClick={() => onToggle(provider, false)}
    >
      <Icon name='broken chain' />
    </Button>
  ) : (
    <Button
      icon
      onClick={() => onToggle(provider, true)}
    >
      <Icon name='chain' />
    </Button>
  );

  return (
    <List.Item key={provider}>
      <Image
        src={image}
        size='mini'
        spaced='right'
      />
      <List.Content>
        <List.Header>{name}</List.Header>
        <List.Description>{description}</List.Description>
      </List.Content>
      <List.Content floated='right'>{button}</List.Content>
    </List.Item>
  );
};

export default ConnectionList;
