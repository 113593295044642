import { useState, useEffect, useReducer } from 'react';
import { users as userApi, games as gameApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useWeeklySummary = (initialUsername, initialGameId) => {
  const [username] = useState(initialUsername);
  const [gameId] = useState(initialGameId);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const fetch = async (user, gameId) => {
      try {
        dispatch({ type: FETCH });
        const response = gameId
          ? await gameApi.getWeeklySummary(gameId)
          : await userApi.getWeeklySummary(user);

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    fetch(username, gameId);
  }, [username, gameId]);

  return [state];
};
