import React from 'react';
import { Container, Segment, Header, Icon } from 'semantic-ui-react';
import { baseURL } from '../../../api';

import OAuthButton from '../../../common/buttons/OAuthButton';

const LoginComponent = () => {
  const redirectUrl = `${window.location.origin}/auth/response`;

  return (
    <Container>
      <Segment textAlign='center'>
        <Header icon>
          <Icon name='sign in' />
          Log into your TempusGameIt account using one of the supported
          authentication providers below
        </Header>
        <Segment.Inline>
          <OAuthButton
            name='Google'
            provider='google plus'
            url={`${baseURL}/auth/google?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Facebook'
            provider='facebook'
            url={`${baseURL}/auth/facebook?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Twitter'
            provider='twitter'
            url={`${baseURL}/auth/twitter?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Discord'
            provider='discord'
            url={`${baseURL}/auth/discord?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Battle.Net'
            provider='battle-net'
            url={`${baseURL}/auth/bnet?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Twitch'
            provider='twitch'
            url={`${baseURL}/auth/twitchtv?redirectTo=${redirectUrl}`}
          />
          <OAuthButton
            name='Steam'
            provider='steam'
            url={`${baseURL}/auth/steam?redirectTo=${redirectUrl}`}
          />
        </Segment.Inline>
      </Segment>
    </Container>
  );
};

export default LoginComponent;
