import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Dimmer, Grid, Loader, Card, Header } from 'semantic-ui-react';
import moment from 'moment';

import { useAuthentication } from '../../../store/authenticationContext.js';
import { userRoute, gameRoute } from '../../../store/siteNavigation';
import { useSession } from '../../../common/hooks/useSession.js';

import SimpleUserCard from '../../../common/cards/user/SimpleUserCard';
import SimpleGameCard from '../../../common/cards/game/SimpleGameCard';
import SessionTimeline from '../../../common/charts/SessionTimeline';
import TimeSummary from '../../../common/sessions/TimeSummary';

import PrivacySegment from './PrivacySegment.jsx';
import CommentsSegment from './CommentsSegment.jsx';
import ScreenshotsSegment from './ScreenshotsSegment.jsx';
import AchievementsSegment from './AchievementsSegment.jsx';

const SessionDetailComponent = () => {
  const navigate = useNavigate();

  const params = useParams();
  const { id } = params;

  const [
    { data: session, isLoading: isLoadingSession },
    setSessionId,
    setComments,
    setPrivacyMode,
  ] = useSession(id);

  const [isEditable, setIsEditable] = useState(false);

  const { authenticatedUser: loggedInUser } = useAuthentication();

  useEffect(() => {
    setIsEditable(
      loggedInUser && session && loggedInUser._id === session.user._id
    );
  }, [loggedInUser, session]);

  const onUserClick = useCallback(
    username => navigate(userRoute(username)),
    [navigate]
  );

  const onGameClick = useCallback(
    gameId => navigate(gameRoute(gameId)),
    [navigate]
  );

  const onCommentsChange = useCallback(
    comments => setComments(id, comments),
    [id]
  );

  const onPrivacyModeChange = useCallback(
    isPrivate => setPrivacyMode(id, isPrivate),
    [id]
  );

  const achievementSegment = useMemo(
    () => (
      <AchievementsSegment
        achievements={session?.achievements}
        gameId={session?.game._id}
      />
    ),
    [session?.achievements, session?.game._id]
  );

  const screenshotSegment = useMemo(
    () => <ScreenshotsSegment screenshots={session?.screenshots} />,
    [session?.screenshots]
  );

  const commentsSegment = useMemo(
    () => (
      <CommentsSegment
        comments={session?.comments}
        isEditable={isEditable}
        isLoading={isLoadingSession}
        onCommentChanged={onCommentsChange}
      />
    ),
    [session?.comments, isEditable]
  );

  const privacySegment = useMemo(
    () => (
      <PrivacySegment
        isPrivate={session?.IsInvisible}
        isEditable={isEditable}
        isLoading={isLoadingSession}
        onPrivacyModeChanged={onPrivacyModeChange}
      />
    ),
    [session?.IsInvisible, isEditable]
  );

  if (!session) {
    return (
      <Dimmer active={isLoadingSession}>
        <Loader />
      </Dimmer>
    );
  }

  const startMoment = new moment(session.StartDate);
  const endMoment = new moment(session.EndDate);

  return (
    <Grid
      columns={2}
      stackable
      centered
      divided
      relaxed
    >
      <Grid.Row>
        <Grid.Column width={3}>
          <Card.Group centered>
            <SimpleGameCard
              id={session.game._id}
              name={session.game.name}
              version={session.Version}
              boxart={session.game.boxart}
              onClick={id => onGameClick(id)}
            />
            <SimpleUserCard
              id={session.user._id}
              username={session.user.username}
              avatar={session.user.avatar}
              onClick={username => onUserClick(username)}
            />
          </Card.Group>
        </Grid.Column>
        <Grid.Column width={10}>
          <Grid
            columns={1}
            centered
          >
            <Grid.Row
              centered
              width={12}
            >
              <Grid.Column>
                <Header
                  as='h4'
                  dividing
                >
                  Summary
                </Header>
                <TimeSummary
                  game={session.game.name}
                  user={session.user.username}
                  startDate={startMoment}
                  endDate={endMoment}
                  activeDuration={session.ActiveDuration}
                  idleDuration={session.IdleDuration}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Header
                  as='h4'
                  dividing
                >
                  Activity Timeline
                </Header>
                <SessionTimeline
                  startDate={session.StartDate}
                  endDate={session.EndDate}
                  idleTime={session.IdleTime}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{privacySegment}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{commentsSegment}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{screenshotSegment}</Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>{achievementSegment}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default SessionDetailComponent;
