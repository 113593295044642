import { useEffect, useReducer, useState } from 'react';
import { games as gameApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useGames = (initialSkip = 0, initialLimit = 100) => {
  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: [],
    pagination: {
      page: 0,
      limit: 50,
    },
  });

  const [skip, setSkip] = useState(initialSkip);
  const [limit] = useState(initialLimit);

  const loadMore = () => {
    setSkip(skip + limit);
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        dispatch({ type: FETCH });
        const response = await gameApi.list({ skip, limit });

        const pagination = {
          page: response.data.currentPage,
          pages: response.data.totalPages,
          limit: limit,
          total: response.data.total,
        };

        dispatch({
          type: FETCH_SUCCESS,
          payload: response.data.games,
          pagination,
        });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    fetch(skip, limit);
  }, [skip, limit]);

  return [state, loadMore];
};
