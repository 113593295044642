import React, { useCallback } from 'react';
import { Container, Grid, Dimmer, Loader, Header } from 'semantic-ui-react';

import WeeklyRankingItemList from '../../common/weekly/WeeklyRankingItemList';
import { gameRoute, userRoute } from '../../store/siteNavigation';
import { useNavigate } from 'react-router-dom';
import { useRankings } from '../../common/hooks/useRankings';

const TopTenContainer = () => {
  const navigate = useNavigate();

  const [{ data: games, isLoading: areGamesLoading }] = useRankings('games');
  const [{ data: users, isLoading: areUsersLoading }] = useRankings('users');

  const onNavigateToGame = useCallback(id => navigate(gameRoute(id)));
  const onNavigateToUser = useCallback(id => navigate(userRoute(id)));

  return (
    <Container>
      <Grid
        columns={2}
        stackable
        divided
        relaxed
      >
        <Grid.Row columns={1}>
          <Grid.Column>{/* What's happening now */}</Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <>
              <Dimmer active={areGamesLoading}>
                <Loader />
              </Dimmer>
              <Header
                as='h4'
                dividing
              >
                Top Games
              </Header>
              <WeeklyRankingItemList
                rankings={games}
                type='game'
                onItemClick={onNavigateToGame}
              />
            </>
          </Grid.Column>
          <Grid.Column>
            <>
              <Dimmer active={areUsersLoading}>
                <Loader />
              </Dimmer>
              <Header
                as='h4'
                dividing
              >
                Top Players
              </Header>
              <WeeklyRankingItemList
                rankings={users}
                type='user'
                onItemClick={onNavigateToUser}
              />
            </>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default TopTenContainer;
