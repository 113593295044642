import { baseURL } from '../api';

export const homeRoute = '/';

export const loginRoute = `/auth/login`;

export const registrationRoute = login => `/auth/register?login=${login}`;

export const gameRoute = gameId => `/games/${gameId}`;

export const userRoute = username => `/users/${username}`;

export const userGameRoute = (username, gameId) =>
  `/users/${username}/game/${gameId}`;

export const userEditRoute = username => `/users/${username}/edit`;

export const sessionRoute = sessionId => `/sessions/${sessionId}`;

export const navigateToProviderConnection = (provider, redirect) => {
  window.location = `${baseURL}/connect/${provider}?redirectTo=${window.location.origin}${redirect}`;
};

export const navigateToProviderDisconnect = (provider, redirect) => {
  window.location = `${baseURL}/disconnect/${provider}?redirectTo=${window.location.origin}${redirect}`;
};
