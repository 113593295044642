import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Dropdown, Form, Input, Button, Message } from 'semantic-ui-react';
import { useWarcraftCharacter } from '../hooks/useWarcraftCharacter';
import { useWarcraftRealms } from '../hooks/useWarcraftRealms';
import CharacterComponent from './CharacterComponent';

const WarcraftCharacterSelector = ({ onAddCharacter }) => {
  const [{ data: realms, isLoading: areRealmsLoading }] = useWarcraftRealms();
  const [
    { data: character, isLoading: isSearchingForCharacter, message },
    lookupCharacter,
  ] = useWarcraftCharacter();

  const [realm, setRealm] = useState('');
  const [name, setName] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    setSearchResult(character);
  }, [character]);

  const handleAddCharacter = async (realm, name) => {
    await onAddCharacter(realm, name);
    setSearchResult(null);
  };

  const isSearchEnabled = useMemo(
    () => !isSearchingForCharacter && realm !== '' && name !== '',
    [realm, name, isSearchingForCharacter]
  );

  const options = realms
    .map(w => ({
      key: w.id,
      text: w.name,
      value: w.slug,
    }))
    .sort((a, b) => (a.text > b.text ? 1 : -1));

  const onSelectRealm = useCallback((e, d) => {
    setRealm(d.value);
  }, []);

  const onSetName = useCallback((e, d) => {
    setName(d.value);
  }, []);

  return (
    <Form
      loading={areRealmsLoading}
      warning={message}
    >
      <Form.Group inline>
        <Form.Field
          control={Dropdown}
          options={options}
          loading={areRealmsLoading}
          search
          placeholder='Select realm'
          selection
          label='Realm'
          onChange={onSelectRealm}
          value={realm}
        />
        <Form.Field
          control={Input}
          placeholder='character name'
          label='Character'
          onChange={onSetName}
          value={name}
        />
        <Form.Field
          control={Button}
          loading={isSearchingForCharacter}
          disabled={!isSearchEnabled}
          onClick={() => lookupCharacter(realm, name)}
        >
          Search
        </Form.Field>
      </Form.Group>
      {searchResult && (
        <CharacterComponent
          key={searchResult.id}
          id={searchResult.id}
          name={searchResult.name}
          realm={searchResult.realm.name}
          avatar={searchResult.media?.bust_url}
          level={searchResult.level}
          race={searchResult.race?.name}
          characterClass={searchResult.character_class?.name}
          onAddClick={handleAddCharacter}
        />
      )}
      <Message
        warning
        header='Character not found'
        content={message}
      />
    </Form>
  );
};

export default WarcraftCharacterSelector;
