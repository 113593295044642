import React, {useState, useEffect, useMemo} from 'react';
import { Menu, Dropdown, Header } from 'semantic-ui-react';
import useDebounce from '../hooks/useDebounce';
import ImageWithFallback from '../images/ImageWithFallback';

const NavigationSearch = ({results = [], isSearching = false, onSearchTextChanged = () => {}, onSelection = () => {}}) => {

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    useEffect( () => {
        onSearchTextChanged(debouncedSearchTerm);
    }, [debouncedSearchTerm, onSearchTextChanged])

    const options = useMemo(() => !results ? [] : results.map( r => ({
        key: r.id,
        text: r.name,
        value: JSON.stringify(r),
        content: (
            <Header as="h4">
                <ImageWithFallback circular={r.type === 'user'} src={r.image} fallback={`/images/${r.type === 'user' ? 'user' : 'game'}.png`} />{r.name}
            </Header>
        )})), [results]);

    return (
        <Menu.Item>
            <Dropdown icon="search"
                      selection
                      search
                      loading={isSearching}
                      placeholder="Search the site"
                      onSearchChange={(e,{searchQuery}) => setSearchTerm(searchQuery)}
                      onChange={(e,{value}) => onSelection(JSON.parse(value))}
                      options={options}
            />
        </Menu.Item>
    )
};

export default NavigationSearch;