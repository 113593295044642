import { useState, useEffect, useReducer } from 'react';
import { sessions as sessionApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useSession = initialId => {
  const [sessionId, setSessionId] = useState(initialId);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const performFetch = async id => {
      try {
        dispatch({ type: FETCH });
        const response = await sessionApi.get(id);

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch(sessionId);
  }, [sessionId]);

  const setComments = async (id, comments) => {
    try {
      dispatch({ type: FETCH });
      const response = await sessionApi.setComments(id, comments);

      dispatch({ type: FETCH_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: e });
      console.error(e);
    }
  };

  const setPrivacyMode = async (id, isPrivate) => {
    try {
      dispatch({ type: FETCH });
      const response = await sessionApi.setPrivacyMode(id, isPrivate);

      dispatch({ type: FETCH_SUCCESS, payload: response.data });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: e });
      console.error(e);
    }
  };

  return [state, setSessionId, setComments, setPrivacyMode];
};
