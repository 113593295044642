import React from 'react';
import './Badge.css';

const Badge = ({id, name, color, url}) => {
    return (
        <a href={url} target='_blank' rel="noopener noreferrer">
            <div className="badge" key={id} style={{backgroundColor: color}}>
                <span className='badge-text'>
                    {name}
                </span>
            </div>
        </a>
    )
}

export default Badge;