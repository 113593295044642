import React from 'react';
import {Menu} from 'semantic-ui-react';
import AuthenticationNavItem from '../../views/authentication/components/AuthenticationNavItem';
import NavigationSearch from './NavigationSearch';

const NavBar = ({location, user, searchResults = [], isSearching = false, onSearchTextChanged = () => {}, onSearchSelected = () => {}, onLogoutClick = () => {}}) => {

    const {pathname} = location;

    return (
        <Menu inverted size="large" style={{borderRadius: 0}}>
            <Menu.Item header link active={pathname==="/"} href="/">
                TempusGameIt
            </Menu.Item>
            <Menu.Item link active={pathname.indexOf("/top-ten") === 0} href="/top-ten">
                Top 10
            </Menu.Item>
            <Menu.Item link active={pathname.indexOf("/games") === 0} href="/games">
                Games
            </Menu.Item>
            <Menu.Item link active={pathname.indexOf("/users") === 0} href="/users">
                Users
            </Menu.Item>
            <Menu.Item link active={pathname.indexOf("/about") === 0} href="/about">
                About
            </Menu.Item>
            <Menu.Menu position="right">
                <NavigationSearch results={searchResults} isSearching={isSearching} onSelection={onSearchSelected} onSearchTextChanged={onSearchTextChanged} />
                <AuthenticationNavItem user={user} onLogoutClick={onLogoutClick} />
            </Menu.Menu>
        </Menu>
      );
}

export default NavBar;
