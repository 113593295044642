import React, { useMemo } from 'react';
import { Grid, Statistic } from 'semantic-ui-react';

import { toPrettyDaysHoursMinutesRounded } from '../../common/dateTimeFormatting.js';
import NoPlayTimePlaceholder from '../../common/NoPlayTimePlaceholder';

const ExpandedStatisticsGrid = ({
  totalActiveDuration = 0,
  averageActiveDuration = 0,
  totalIdleDuration = 0,
  averageIdleDuration = 0,
  totalSessions = 0,
  totalPlayerOrGames = 0,
  show = '',
}) => {
  if (totalSessions === 0) {
    return <NoPlayTimePlaceholder />;
  }

  const label = useMemo(
    () => (!!show ? (show === 'players' ? 'Players' : 'Games') : null),
    [show]
  );

  const totalPlayerOrGameComponent = useMemo(() => {
    return !!show ? (
      <Statistic
        size='mini'
        value={totalPlayerOrGames}
        label={label}
      />
    ) : null;
  }, [show, totalPlayerOrGames, label]);

  return (
    <Grid
      stackable
      centered
    >
      <Grid.Row columns={3}>
        <Grid.Column textAlign='center'>
          <Statistic
            size='mini'
            value={toPrettyDaysHoursMinutesRounded(totalActiveDuration)}
            label='Active Time'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Statistic
            size='mini'
            value={toPrettyDaysHoursMinutesRounded(averageActiveDuration)}
            label='Average Active'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Statistic
            size='mini'
            value={totalSessions}
            label='Sessions'
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3}>
        <Grid.Column textAlign='center'>
          <Statistic
            size='mini'
            value={toPrettyDaysHoursMinutesRounded(totalIdleDuration)}
            label='Idle Time'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Statistic
            size='mini'
            value={toPrettyDaysHoursMinutesRounded(averageIdleDuration)}
            label='Average Idle'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          {totalPlayerOrGameComponent}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default ExpandedStatisticsGrid;
