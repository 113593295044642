import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Item } from 'semantic-ui-react';
import GameHistoryItem from './GameHistoryItem';

export default class GameHistoryItemList extends PureComponent {

    render() {
        const {history, onViewUserGameHistory} = this.props;

        return (
            <Item.Group divided>
            {
              history.map(h => {
                return <GameHistoryItem key={h.id} history={h} onViewClick={onViewUserGameHistory}/>
              })
            }
            </Item.Group>
        );
    }
}

GameHistoryItemList.propTypes = {
    history: PropTypes.array,
    onViewUserGameHistory: PropTypes.func,
};

GameHistoryItemList.defaultProps = {
    history: [],
    onViewUserGameHistory: () => {},
};