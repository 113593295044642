import React, { useMemo } from 'react';
import { Grid, Statistic } from 'semantic-ui-react';
import HeatMap from 'react-heatmap-grid';
import moment from 'moment';
import { toPrettyDaysHoursMinutesRounded } from '../dateTimeFormatting';
import NoPlayTimePlaceholder from '../NoPlayTimePlaceholder';

const WeeklyStats = ({ totalDuration = 0, totalSessions = 0, dates = [] }) => {
  if (!totalSessions) {
    return <NoPlayTimePlaceholder />;
  }

  const heatMap = useMemo(
    () => dates.map(dateDetails => dateDetails.totalDuration),
    [dates]
  );
  const labels = useMemo(
    () =>
      dates.map(dateDetails => new moment(dateDetails.date).format('ddd M/D')),
    [dates]
  );

  return (
    <Grid
      stackable
      divided='vertically'
    >
      <Grid.Row columns={3}>
        <Grid.Column textAlign='center'>
          <Statistic
            size='tiny'
            value={totalSessions}
            label='Total Sessions'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Statistic
            size='tiny'
            value={toPrettyDaysHoursMinutesRounded(totalDuration)}
            label='Total Duration'
          />
        </Grid.Column>
        <Grid.Column textAlign='center'>
          <Statistic
            size='tiny'
            value={toPrettyDaysHoursMinutesRounded(
              totalDuration / totalSessions
            )}
            label='Average Session'
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <HeatMap
            xLabels={labels}
            yLabels={['']}
            yLabelWidth={0}
            data={[heatMap]}
            cellStyle={(background, value, min, max, data, x, y) => ({
              background:
                value === 0
                  ? '#F0F0F0'
                  : `rgba(130,157,180, ${
                      1 - (max - value) / (max - min) + 0.25
                    })`,
            })}
            title={(value, unit) => `${toPrettyDaysHoursMinutesRounded(value)}`}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WeeklyStats;
