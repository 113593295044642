import React, { Fragment } from 'react';
import { Button, Icon, Loader, Dimmer } from 'semantic-ui-react';

import NoPlayTimePlaceholder from '../NoPlayTimePlaceholder';
import SessionItemList from './SessionItemList';

const SessionFeedComponent = ({
  sessions = [],
  isLoading = false,
  showLoadMore = false,
  showUser = false,
  onViewSession = () => {},
  onViewUser = () => {},
  onLoadMore = () => {},
}) => {
  const viewMoreButton = showLoadMore ? (
    <Button
      icon
      labelPosition='left'
      size='tiny'
      onClick={() => onLoadMore()}
    >
      <Icon name='angle double down' />
      More
    </Button>
  ) : null;

  const content =
    !sessions || sessions.length === 0 ? (
      <NoPlayTimePlaceholder />
    ) : (
      <Fragment>
        <SessionItemList
          sessions={sessions}
          showUser={showUser}
          onViewSession={onViewSession}
          onViewUser={onViewUser}
        />
        {viewMoreButton}
      </Fragment>
    );

  return (
    <div>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      {content}
    </div>
  );
};

export default SessionFeedComponent;
