import Cookies from 'js-cookie';
import storageAvailable from 'storage-available';

const getJsonItem = (key) => {
    let itemString;

    if(storageAvailable('localStorage')) {
        itemString = localStorage.getItem(key);
    } else {
        itemString = Cookies.get(key);
    }

    try {
        return JSON.parse(itemString);
    } catch(error) {}

    return null;
}

const getItem = (key) => {
    if(storageAvailable('localStorage')) {
        return localStorage.getItem(key);
    } else {
        return Cookies.get(key);
    }
}

const setItem = (key, item) => {

    if(storageAvailable('localStorage')) {
        localStorage.setItem(key, item);
    } else {
        Cookies.set(key, item);
    }

}

const setJsonItem = (key, item) => {
    const itemString = JSON.stringify(item);

    setItem(key, itemString);
}

const removeItem = (key) => {
    if(storageAvailable('localStorage')) {
        localStorage.removeItem(key);
    } else {
        Cookies.remove(key);
    }
}

export default {
    getJsonItem,
    getItem,
    setJsonItem,
    setItem,
    removeItem,
};