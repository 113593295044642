import React, { useCallback } from 'react';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import CatalogGameCard from '../../../common/cards/game/CatalogGameCard';
import { useNavigate } from 'react-router-dom';
import { useGames } from '../../../common/hooks/useGames';
import { gameRoute } from '../../../store/siteNavigation';

const GamesIndexContainer = () => {
  const navigate = useNavigate();

  const [{ data: games, isLoading }] = useGames();

  const onViewGame = useCallback(id => navigate(gameRoute(id)), [navigate]);

  const columns = games.map(g => {
    return (
      <Grid.Column
        key={g._id}
        style={{ marginBottom: '20px' }}
      >
        <CatalogGameCard
          id={g._id}
          name={g.Name}
          key={g._id}
          boxart={g.boxart}
          onClick={onViewGame}
        />
      </Grid.Column>
    );
  });

  return (
    <>
      <Dimmer active={isLoading}>
        <Loader />
      </Dimmer>
      <Grid
        stackable
        doubling
        style={{ marginLeft: '80px', marginRight: '80px' }}
      >
        <Grid.Row
          columns={6}
          only='computer'
        >
          {columns}
        </Grid.Row>
        <Grid.Row
          columns={4}
          only='tablet'
        >
          {columns}
        </Grid.Row>
        <Grid.Row
          columns={2}
          only='mobile'
        >
          {columns}
        </Grid.Row>
      </Grid>
    </>
  );
};

export default GamesIndexContainer;
