import React from 'react';
import { NavLink } from 'react-router-dom'
import { Image, Button, Icon, Menu } from 'semantic-ui-react';

const LoginNavItem = () => {
    return (
        <Menu.Item link>
            <NavLink className="nav-link" to="/auth/login">Login</NavLink>
        </Menu.Item>
    )
};

const UserNavItem = ({username, avatar, onLogoutClick = () => {}}) => {
    return (
        <>
            <Menu.Item link href={`/users/${username}`}>
                <Image avatar src={avatar} spaced="right"/>
                {username}
            </Menu.Item>
            <Menu.Item>
                <Button basic icon title="Sign out" onClick={onLogoutClick} inverted size="tiny">
                    <Icon name="sign-out" />
                </Button>
            </Menu.Item>
        </>
    )
};

const AuthenticationNavItem = ({user, onLogoutClick = () => {}}) => {
    return user ?
            <UserNavItem username={user.username} avatar={user.avatar} onLogoutClick={onLogoutClick} /> :
            <LoginNavItem />;
}

export default AuthenticationNavItem;
