import React from 'react';
import { Header } from 'semantic-ui-react';
import Gallery from '../../../common/images/Gallery';

const ScreenshotsSegment = ({ screenshots = [] }) => {
  if (!screenshots?.length) {
    return null;
  }

  return (
    <>
      <Header
        as='h4'
        dividing
      >{`Screenshots (${screenshots.length})`}</Header>
      <Gallery screenshots={screenshots} />
    </>
  );
};

export default ScreenshotsSegment;
