import React from 'react';
import { Route, Routes } from 'react-router';

import SessionDetailComponent from './detail/SessionDetailComponent';

const SessionsContainer = () => (
  <Routes>
    <Route
      exact
      path='/:id'
      element={<SessionDetailComponent />}
    />
  </Routes>
);

export default SessionsContainer;
