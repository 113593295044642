import React from 'react';
import { Route, Routes } from 'react-router';

import LoginComponent from './login/LoginComponent';
import LoginResponseComponent from './login/LoginResponseComponent';
import RegistrationComponent from './login/RegistrationComponent';

const AuthenticationContainer = () => (
  <Routes>
    <Route
      exact
      path='/login'
      element={<LoginComponent />}
    />
    <Route
      exact
      path='/register'
      element={<RegistrationComponent />}
    />
    <Route
      path='/response'
      element={<LoginResponseComponent />}
    />
  </Routes>
);

export default AuthenticationContainer;
