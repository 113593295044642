import React from 'react';
import { Header, Icon, Segment } from 'semantic-ui-react';

const NoScreenshotsPlaceholder = ({attached = false}) => (
        <Segment placeholder attached={attached}>
            <Header icon>
                <Icon name='images outline' />
                No screenshots have been taken
            </Header>
        </Segment>
);

export default NoScreenshotsPlaceholder;