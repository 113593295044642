import React from 'react'
import { Card, Image, Placeholder } from 'semantic-ui-react';
import ImageWithFallback from '../../images/ImageWithFallback';

const SimpleUserCard = ({id, username, avatar, onClick = () => {}, isLoading = false}) => {

    const image = !isLoading ?
                    <ImageWithFallback size="medium" src={avatar} fallback="/images/user.png" /> :
                    <Placeholder><Placeholder.Image square /></Placeholder>;

    const header = !isLoading ?
                    <Card.Content header={username} /> :
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line length="medium" />
                        </Placeholder.Header>
                    </Placeholder>;

    return (
        <Card onClick={() => onClick(username)} key={id}>
            {image}
            {header}
        </Card>
    )
};

export default SimpleUserCard;
