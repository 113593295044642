import React from 'react';
import { Header } from 'semantic-ui-react';
import CommentsComponent from '../../../common/sessions/CommentsComponent';

const CommentsSegment = ({
  comments,
  isEditable,
  isLoading,
  onCommentChanged = () => {},
}) => {
  if (!comments && !isEditable) {
    return null;
  }

  return (
    <>
      <Header
        as='h4'
        dividing
      >
        Comments
      </Header>
      <CommentsComponent
        comments={comments}
        isSaving={isLoading}
        isEditable={isEditable}
        onChange={onCommentChanged}
      />
    </>
  );
};

export default CommentsSegment;
