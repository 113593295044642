import React from 'react';
import { Icon, Grid, Header } from 'semantic-ui-react';
import {toPrettyDaysHoursMinutes} from '../dateTimeFormatting'

const TimeSummary = ({game, user = null, activeDuration, idleDuration, startDate, endDate, onGameClick = () => {}, onUserClick = () => {}}) => {

    const endItem= endDate && endDate.year() !== 1 ?
                            <>
                                <Grid.Column width={1} textAlign="left"><Icon size="big" name="stop circle outline" /></Grid.Column>
                                <Grid.Column width={4} textAlign="left" verticalAlign="middle"><Header as="h4">{endDate.calendar()}</Header></Grid.Column>
                            </> :
                            null;

    const userItem = user !== null ?
                            <>
                                <Grid.Column width={1} textAlign="left"><Icon size="big" name="user" /></Grid.Column>
                                <Grid.Column textAlign="left" verticalAlign="middle"><Header as="h4">{user}</Header></Grid.Column>
                            </> :
                            null;

    const idleDisplay = idleDuration === 0 ? 'No time' : toPrettyDaysHoursMinutes(idleDuration);

    return (
        <Grid columns={4} stackable>
            <Grid.Row>
                <Grid.Column width={1} textAlign="left"><Icon size="big" name="game" /></Grid.Column>
                <Grid.Column textAlign="left" verticalAlign="middle"><Header as="h4">{game}</Header></Grid.Column>
                {userItem}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={1} textAlign="left"><Icon size="big" name="play circle outline" /></Grid.Column>
                <Grid.Column textAlign="left" verticalAlign="middle"><Header as="h4">{startDate.calendar()}</Header></Grid.Column>
                {endItem}
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={1} textAlign="left"><Icon size="big" name="rocket" /></Grid.Column>
                <Grid.Column textAlign="left" verticalAlign="middle"><Header as="h4" content={toPrettyDaysHoursMinutes(activeDuration)} subheader="active" /></Grid.Column>
                <Grid.Column width={1} textAlign="left"><Icon size="big" name="hourglass outline" /></Grid.Column>
                <Grid.Column textAlign="left" verticalAlign="middle"><Header as="h4" content={idleDisplay} subheader="idle" /></Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default TimeSummary;