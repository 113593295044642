import {sortBy} from 'lodash';
import colors from '../Colors';

export default class DayOfWeekSummaryModel {
    constructor(summaryData) {
        this._data = summaryData;

        this._gross = [];
        this._average = [];

        const {grossResults, averageResults, dataKeys} = this._computeGrossAndAverage();

        this._gross = grossResults;
        this._average = averageResults;
        this._dataKeys = dataKeys;
        this._maxGross = Math.max(...this._gross.map(g => g.totalDuration));
        this._maxAverage = Math.max(...this._average.map(g => g.maxAverage));
    }

    get gross() {
        return this._gross;
    }

    get average() {
        return this._average;
    }

    get maximumGrossDuration() {
        return this._maxGross;
    }

    get maximumAverageDuration() {
        return this._maxAverage;
    }

    get dataKeys() {
        return this._dataKeys;
    }

    _computeGrossAndAverage = () => {
        let grossResults = [];
        let averageResults = [];
        let dataKeys = [];

        const sortedByDuration = sortBy(this._data, d => -d.totalDuration);

        const topFive = sortedByDuration.slice(0, 5);
        const others = sortedByDuration.slice(5);

        // Initialize the result arrays
        for(let i=0; i < 7; i++) {
            grossResults.push({
                dayOfWeek: i,
                totalDuration: 0,
                totalSessions: 0,
            });

            averageResults.push({
                dayOfWeek: i,
                totalDuration: 0,
                totalSessions: 0,
                maxAverage: 0,
                othersTotalDuration: null,
                othersTotalSessions: 0,
            });
        }

        for(const game of topFive) {
            // Push the game name into the datakeys
            dataKeys.push(game.name);
            // Build the gross week for the current game
            this._buildGrossWeek(game.name, game.days, grossResults);
            this._buildAverageWeek(game.name, game.days, averageResults);
        }

        for(const game of others) {
            this._buildGrossWeek(game.name, game.days, grossResults, true);
            this._buildAverageWeek(game.name, game.days, averageResults, true);
        }

        // Iterate over all of the latest average entities, and compute the others average
        for(let averageDay of averageResults.filter( a => a["othersTotalDuration"] !== null)) {
            averageDay["Others"] = averageDay["othersTotalDuration"] / averageDay["othersTotalSessions"];
        }

        if(others.length > 0) {
            dataKeys.push("Others");
        }

        return {grossResults, averageResults, dataKeys};
    }

    _buildGrossWeek(name, days, grossResults, isOthers = false) {

        const key = isOthers ? "Others" : name;

        for(const day of days) {
            let currentDayOfWeek = grossResults[day.dayOfWeek];

            if(!currentDayOfWeek[key]) {
                currentDayOfWeek[key] = null;
            }

            // If there's a duration, then increment the current duration
            //  We use an increment here, instead of assignment, so that
            //  we can push in "Others" here too without having to write more code
            if(day.duration > 0) {
                currentDayOfWeek[key] = (currentDayOfWeek[key] || 0) + day.duration;
            }

            currentDayOfWeek.totalDuration += day.duration;
            currentDayOfWeek.totalSessions += day.sessions;

            if(isOthers) {
                this._buildOthersTooltip(currentDayOfWeek, name, day.duration);
            }
        }
    }

    _buildAverageWeek(name, days, averageResults, isOthers = false) {
        for(const day of days) {
            let currentDayOfWeek = averageResults[day.dayOfWeek];

            currentDayOfWeek[name] = day.duration === 0 ? null : day.duration / day.sessions;
            currentDayOfWeek.totalDuration += day.duration;
            currentDayOfWeek.totalSessions += day.sessions;

            if(!isOthers) {
                currentDayOfWeek.maxAverage += currentDayOfWeek[name] ? currentDayOfWeek[name] : 0
            } else {
                if(day.duration > 0) {
                    currentDayOfWeek.othersTotalDuration = (currentDayOfWeek.othersTotalDuration || 0) + day.duration;
                    currentDayOfWeek.othersTotalSessions += day.sessions;
                }

                this._buildOthersTooltip(currentDayOfWeek, name, day.duration/day.sessions);
            }
        }
    }

    _buildOthersTooltip(dayEntity, name, duration) {
        // If there's no gross Others Tooltip array, create it now
        if(!dayEntity["OthersTooltip"]) {
            dayEntity["OthersTooltip"] = [];
        }

        // If there's a valid durations, then push it into the "OthersTooltip"
        //  array.  This is used for displaying game details of the other games
        //  in the tooltip
        if(duration > 0) {
            dayEntity["OthersTooltip"].push({
                name: name,
                value: duration,
                fill: colors[colors.length - 1]
            });
        }
    }
}