import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Item, List, Image } from 'semantic-ui-react';
import Moment from 'react-moment';

import {toPrettyDaysHoursMinutesRounded} from '../../../../../common/dateTimeFormatting';
import ImageWithFallback from '../../../../../common/images/ImageWithFallback';

export default class GameHistoryItem extends PureComponent {

    static propTypes = {
        history: PropTypes.object.isRequired,
        type: PropTypes.oneOf(["game","user"]),
        onViewClick: PropTypes.func,
    };

    static defaultProps = {
        type: 'game',
        onViewClick: () => {},
    };

    render() {
        const {history, type, onViewClick} = this.props;

        return (
            <Item>
                <ImageWithFallback size='tiny' src={history.boxart} fallback="/images/user.png"/>
                <Item.Content>
                    <Item.Header as='a' onClick={() => onViewClick(type === 'game' ? history.id : history.name)}>{history.name}</Item.Header>
                    <Item.Description>
                        <List horizontal divided>
                            <List.Item>
								<List.Content>{toPrettyDaysHoursMinutesRounded(history.totalActiveTime)} played</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>{history.totalSessions} sessions</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>{toPrettyDaysHoursMinutesRounded(history.totalActiveTime/history.totalSessions)} average</List.Content>
							</List.Item>
                        </List>
                    </Item.Description>
                    <Item.Extra>
                        Last played <Moment calendar date={history.latestSession.EndDate} />
                    </Item.Extra>
                </Item.Content>
            </Item>
        )
    }
}

