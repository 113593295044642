import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { faBattleNet, faDiscord, faGooglePlus, faTwitch, faSteam, faTwitter, faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import './OAuthButton.css';

const OAuthButton = ({provider, url, name, disabled = false, title = ''}) => {

    const [icon, setIcon] = useState('faPlus');

    useEffect(() => {
        let i;

        switch(provider) {
            case "battle-net":
                i = faBattleNet;
                break;
            case "discord":
                i = faDiscord;
                break;
            case "twitch":
                i = faTwitch;
                break;
            case "steam":
                i = faSteam;
                break;
            case 'google plus':
                i = faGooglePlus;
                break;
            case 'facebook':
                i = faFacebookSquare;
                break;
            case 'twitter':
                i = faTwitter;
                break;
            default:
                i = faPlus;
                break;
        }

        setIcon(i);
    }, [provider]);

    const button = <Button className={provider} disabled={disabled} title={title}>
                        <FontAwesomeIcon icon={icon} size="2x" className="faIcon" />
                        {name}
                    </Button>;

    return disabled ? button : <a href={url} target="_self">{button}</a>
}

export default OAuthButton;