import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import moment from 'moment';

import { Header } from 'semantic-ui-react';
import TopItemList from '../../../../common/lists/top-items/TopItemList';
import ChartDateRangeMenu from '../../../../common/navigation/ChartDateRangeMenu';
import ExpandedStatisticsGrid from '../../../../common/statistics/ExpandedStatisticsGrid';
import VisualizationComponent from '../../../../common/charts/VisualizationComponent';
import DateSummaryModel from '../../../../common/charts/bar/date/DateSummaryModel';
import DayOfWeekSummaryModel from '../../../../common/charts/bar/day-of-week/DayOfWeekSummaryModel';
import HourOfDaySummaryModel from '../../../../common/charts/bar/hour-of-day/HourOfDaySummaryModel';
import NoPlayTimePlaceholder from '../../../../common/NoPlayTimePlaceholder';
import ExportUserSessionComponent from '../../common/ExportUserSessionComponent';

export default class UserInsightComponent extends PureComponent {
  static propTypes = {
    topGames: PropTypes.array,
    statistics: PropTypes.object,
    onDateChanged: PropTypes.func,
    onTopGameClicked: PropTypes.func,
    onVisualizationChanged: PropTypes.func,
    visualizationData: PropTypes.oneOfType([
      PropTypes.instanceOf(DateSummaryModel),
      PropTypes.instanceOf(DayOfWeekSummaryModel),
      PropTypes.instanceOf(HourOfDaySummaryModel),
    ]),
    isVisualizationLoading: PropTypes.bool,
    isExportVisible: PropTypes.bool,
  };

  static defaultProps = {
    topGames: [],
    statistics: { totalSessions: 0, totalGames: [] },
    visualizationData: null,
    onDateChanged: () => {},
    onTopGameClicked: () => {},
    onVisualizationChanged: () => {},
    onDownload: () => {},
    isVisualizationLoading: false,
    isExportVisible: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      startDate: '',
      endDate: '',
    };
  }

  componentDidMount() {
    this.props.onDateChanged(
      moment().startOf('day').add(-1, 'weeks').toDate(),
      moment().endOf('day').toDate()
    );
  }

  getTopGames = memoize(topGames =>
    topGames.map(t => {
      return {
        id: t._id,
        name: t.name,
        image: t.image,
        totalDuration: t.summary.activeDuration,
        totalSessions: t.summary.totalSessions,
        lastPlayed: t.lastPlayed,
      };
    })
  );

  handleDateChange = (startDate, endDate) => {
    this.setState(() => ({
      startDate: new moment(startDate).format('YYYY-MM-DD'),
      endDate: new moment(endDate).format('YYYY-MM-DD'),
    }));

    this.props.onDateChanged(startDate, endDate);
  };

  render() {
    const {
      onTopGameClicked,
      onVisualizationChanged,
      statistics,
      visualizationData,
      isVisualizationLoading,
      isExportVisible,
    } = this.props;
    const { startDate, endDate } = this.state;
    const topGames = this.getTopGames(this.props.topGames);

    const stats = statistics ? (
      <ExpandedStatisticsGrid
        totalActiveDuration={statistics.activeDuration}
        averageActiveDuration={statistics.averageActiveDuration}
        totalIdleDuration={statistics.idleDuration}
        averageIdleDuration={statistics.averageIdleDuration}
        totalSessions={statistics.totalSessions}
        totalPlayerOrGames={
          statistics.totalGames ? statistics.totalGames.length : 0
        }
        show='games'
      />
    ) : (
      <NoPlayTimePlaceholder />
    );

    return (
      <Fragment>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <ChartDateRangeMenu
            onSearch={this.handleDateChange}
            startDate={startDate}
            endDate={endDate}
          />
          {!!isExportVisible && (
            <ExportUserSessionComponent
              startDate={startDate}
              endDate={endDate}
            />
          )}
        </div>
        <Header
          as='h4'
          dividing
        >
          Summary
        </Header>
        {stats}
        <Header
          as='h4'
          dividing
        >
          Top Games
        </Header>
        <TopItemList
          items={topGames}
          onItemClick={onTopGameClicked}
        />
        <Header
          as='h4'
          dividing
        >
          Visualization
        </Header>
        <VisualizationComponent
          onChartChanged={onVisualizationChanged}
          chartModel={visualizationData}
          onDateChanged={this.handleDateChange}
          isLoading={isVisualizationLoading}
        />
      </Fragment>
    );
  }
}
