import React, {PureComponent} from 'react';
import {sortBy} from 'lodash';
import { Segment, List, Header } from 'semantic-ui-react';
import { toPrettyDaysHoursMinutesRounded } from '../../../dateTimeFormatting';
import {longFormat} from './DateUnitFormatter';

export default class DateBarTooltip extends PureComponent {
    render() {
        const { payload } = this.props;

        if(!payload || payload.length === 0) {
            return null;
        }

        // Pull out the others detail
        const othersDetail = payload[0].payload["OthersTooltip"] || [];

        // Concat the top 5 and others arrays together
        const sessions = [...payload.filter(p => p.name !== 'Others'), ...othersDetail];

        // Sort 'em by duration descending
        const sortedPayload = sortBy(sessions, p => -p.value);

        return (
            <Segment>
                <List>
                    {
                        sortedPayload.map( p => (
                            <List.Item key={p.name}>
                                <List.Icon name="square" style={{color: p.fill}} />
                                <List.Content>
                                    <List.Header>{p.name}</List.Header>
                                    <List.Description>{toPrettyDaysHoursMinutesRounded(p.value)}</List.Description>
                                </List.Content>
                            </List.Item>
                        ))
                    }
                </List>
                <Header as="h5">
                    <Header.Content>
                        {longFormat(payload[0].payload.date, payload[0].payload.unit)}
                    </Header.Content>
                </Header>
            </Segment>
        )
    }
}