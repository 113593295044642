import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'semantic-ui-react';
import WarcraftAchievementListItem from './WarcraftAchievementListItem';
import Everquest2AchievementListItem from './Everquest2AchievementListItem';
import GeneralAchievementListItem from './GeneralAchievementListItem';

export default class AchievementList extends PureComponent {

	static propTypes = {
		achievements: PropTypes.array.isRequired,
		gameId: PropTypes.string.isRequired,
	};

	static defaultProps = {
		achievements: [],
		gameId: '',
	};

	render() {
		const { achievements, gameId } = this.props;

		return (
			<List divided relaxed verticalAlign="middle">
			{
				achievements.map( a => {
					switch(gameId) {
						case '511b17ac706515101a000007':
							return <Everquest2AchievementListItem achievement={a} key={a.id} />
						case '510fb456902a601858cb43a1':
							return <WarcraftAchievementListItem achievement={a} key={a.id} />
						default:
							return <GeneralAchievementListItem achievement={a} key={a.id} />;
					}
				})
			}
			</List>
		);
	}
}
