export const UPDATE = 'UPDATE';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const UPDATE_NOT_FOUND = 'UPDATE_NOT_FOUND';

export const apiUpdateReducer = (state, action) => {
  switch (action.type) {
    case UPDATE:
      return {
        ...state,
        isUpdating: true,
        error: null,
        message: undefined,
      };
    case UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        error: null,
        data: action.payload,
        message: undefined,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        isUpdating: false,
        error: action.payload,
        data: null,
        message: undefined,
      };
    case UPDATE_NOT_FOUND:
      return {
        ...state,
        isUpdating: false,
        message: action.message,
        data: null,
      };
    default:
      throw new Error();
  }
};
