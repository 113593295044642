import { useState, useEffect, useReducer } from 'react';
import { users as userApi, games as gameApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useStatistics = (
  initialUsername,
  initialGameId,
  initialStartDate,
  initialEndDate
) => {
  const [username] = useState(initialUsername);
  const [gameId] = useState(initialGameId);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const fetch = async (user, gameId, startDate, endDate) => {
      try {
        dispatch({ type: FETCH });
        const response =
          gameId && user
            ? await userApi.getGameStatistics(user, gameId, startDate, endDate)
            : user && !gameId
            ? await userApi.getStatistics(user, startDate, endDate)
            : await gameApi.getStatistics(gameId, startDate, endDate);

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    fetch(username, gameId, startDate, endDate);
  }, [username, gameId, startDate, endDate]);

  return [state, setStartDate, setEndDate];
};
