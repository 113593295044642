import { useState, useEffect, useReducer } from 'react';
import {search as searchApi} from '../../api';
import {FETCH, FETCH_ERROR, FETCH_SUCCESS, apiReducer} from '../../common/reducers/apiReducer';

export const useSearchApi = (initialText) => {

    const [searchText, setSearchText] = useState(initialText);

    const [state, dispatch] = useReducer(apiReducer, {
        isLoading: false,
        error: null,
        data: null,
    });

    useEffect(() => {

        const performFetch = async (text) => {
            try {
                dispatch({type: FETCH});
                const response = text && text !== "" ? await searchApi.basic(text) : {data: []}

                dispatch({type: FETCH_SUCCESS, payload: response.data});
            } catch(e) {
                dispatch({type: FETCH_ERROR, payload: e});
                console.error(e);
            }
        }

        performFetch(searchText);
    }, [searchText])

    return [state, setSearchText];
}
