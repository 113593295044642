import React from 'react';
import {Link} from 'react-router-dom';
import { Header, Grid, Image, Icon } from 'semantic-ui-react';

const AboutComponent = () => {

    return (
        <>
            <Grid container columns={2} relaxed>
                <Grid.Row centered>
                    <Grid.Column>
                        <Header>
                            We created TempusGameIt to help you see what and how you play
                        </Header>
                        <p>
                            TempusGameIt is all about helping you document as you play.
                        </p>
                        <p>
                        We're all about preserving memories
                        as you play.  Sure screenshots are great, but we think they're even better when you know when
                        that screenshot was taken, who you played with, and notes about the awesome dragons you slayed.
                        </p>
                    </Grid.Column>
                    <Grid.Column only="computer" width={3}>
                        <Image size="small" src="https://upload.wikimedia.org/wikipedia/commons/e/e9/Doomsday_clock_%2816_minutes%29.svg" alt="'File:Doomsday clock (16 minutes).svg' by Ryanicus Girraficus is licensed under CC0 1.0. To view a copy of this license, visit http://creativecommons.org/publicdomain/zero/1.0/deed.en"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid container columns={2} relaxed>
                <Grid.Row centered>
                    <Grid.Column>
                        <Header>
                            Who we are
                        </Header>
                        <p>
                            We are currently a team of 1--{<Link to="/users/kevin">Kevin</Link>}, and TempusGameIt is his passion project.
                            This application helps him to iterate on technology, explore new languages, implement changing best practices, and pursue different ways of thinking.
                        </p>
                        <p>
                            We are committed to building a quality, stable product that respects data ownership.  We are also guided by principal
                            that great software is based on solving problems in an intuitive, natural way.
                        </p>
                    </Grid.Column>
                    <Grid.Column only="computer" width={3}>
                        <Icon name="user" size="massive"/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Grid container columns={2} relaxed>
                <Grid.Row centered>
                    <Grid.Column>
                        <Header>
                            Get in touch
                        </Header>
                        <p>
                            Join the discussion on <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/934jSKEyTG">discord</a>
                        </p>
                        <p>
                            See what's on tap on our <a target="_blank" rel="noopener noreferrer" href="https://trello.com/b/0qj8Jtr9">trello</a> board
                        </p>
                    </Grid.Column>
                    <Grid.Column only="computer" width={3}>
                        <Icon name="trello" size="massive" />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </>
    )
};

export default AboutComponent;