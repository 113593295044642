import { useState, useEffect, useReducer } from 'react';
import { sessions as sessionApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducerPaged,
} from '../reducers/apiReducerPaged';

export const useSessionApi = (
  initialUser,
  initialGame,
  initialSkip = 0,
  initialLimit = 20
) => {
  const [gameId] = useState(initialGame);
  const [username] = useState(initialUser);
  const [skip, setSkip] = useState(initialSkip);
  const [limit] = useState(initialLimit);

  const [state, dispatch] = useReducer(apiReducerPaged, {
    isLoading: false,
    error: null,
    data: null,
    pagination: {
      page: 0,
      limit: 50,
    },
  });

  const loadMore = () => {
    setSkip(skip + limit);
  };

  useEffect(() => {
    const performFetch = async (username, gameId, skip, limit) => {
      try {
        dispatch({ type: FETCH });

        const response =
          gameId && username
            ? await sessionApi.getSessionsForUserGame(
                username,
                gameId,
                skip,
                limit
              )
            : username
            ? await sessionApi.getSessionsForUser(username, skip, limit)
            : await sessionApi.getSessionsForGame(gameId, skip, limit);

        const pagination = {
          page: response.data.currentPage,
          pages: response.data.totalPages,
          limit: limit,
          total: response.data.total,
        };

        dispatch({
          type: FETCH_SUCCESS,
          payload: response.data.sessions,
          pagination: pagination,
        });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch(username, gameId, skip, limit);
  }, [gameId, username, skip, limit]);

  return [state, loadMore];
};
