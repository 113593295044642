import { authentication as authApi } from '../api';
import { createContext, useState, useContext, useEffect } from 'react';
import browserStorage from '../common/browserStorage';
import { homeRoute } from '../store/siteNavigation';
import growthbook from './growthbook';

// Create the context
export const AuthenticationContext = createContext(null);

// Create a provider component
export const AuthenticationProvider = ({ children }) => {
  const [authenticatedUser, setAuthenticatedUser] = useState(null);

  const setUserInformation = user => {
    setAuthenticatedUser(user);

    growthbook.setAttributes({
      id: user._id,
    });
  };

  useEffect(() => {
    // This code was previously in componentDidMount, but was causing a problem
    //  because I wanted the user to be placed into local storage before the render
    //  since the render had the potential to render an authenticated route
    const user = browserStorage.getJsonItem('user');

    if (user) {
      setUserInformation(user);
    }
  }, []);

  const completeLogin = async user => {
    browserStorage.setJsonItem('user', user);
    browserStorage.setItem('token', user.token);

    setUserInformation(user);
  };

  const logout = async () => {
    try {
      await authApi.logout();

      // Remove the user from local storage
      browserStorage.removeItem('user');
      browserStorage.removeItem('token');

      // Clear the authenticated user in context
      setAuthenticatedUser(null);

      growthbook.setAttributes({});

      // Redirect to the home page
      window.location.href = homeRoute;
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <AuthenticationContext.Provider
      value={{ authenticatedUser, completeLogin, logout }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

// Custom hook for using the authentication context
export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};
