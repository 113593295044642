import { useCallback, useReducer } from 'react';
import { warcraft as wowApi, everquest2 as eq2Api } from '../../../../api';
import {
  UPDATE,
  UPDATE_ERROR,
  UPDATE_SUCCESS,
  apiUpdateReducer,
} from '../../../../common/reducers/apiUpdateReducer';

export const useUserCharacter = (characters = []) => {
  const [state, dispatch] = useReducer(apiUpdateReducer, {
    isLoading: false,
    error: null,
    data: characters,
  });

  const addCharacterToUser = useCallback(
    async (realm, name, game = 'wow') => {
      try {
        dispatch({ type: UPDATE });

        const response =
          game === 'eq2'
            ? await eq2Api.add(realm, name)
            : await wowApi.add(name, realm);

        dispatch({
          type: UPDATE_SUCCESS,
          payload: response.data,
        });

        return response.data;
      } catch (e) {
        dispatch({ type: UPDATE_ERROR, payload: e });
        console.error(e);
      }
    },
    [dispatch]
  );

  const removeCharacterFromUser = useCallback(
    async (id, game = 'wow') => {
      try {
        dispatch({ type: UPDATE });

        const response =
          game === 'eq2' ? await eq2Api.delete(id) : await wowApi.delete(id);

        dispatch({
          type: UPDATE_SUCCESS,
          payload: response.data,
        });

        return response.data;
      } catch (e) {
        dispatch({ type: UPDATE_ERROR, payload: e });
        console.error(e);
      }
    },
    [dispatch]
  );

  return [state, addCharacterToUser, removeCharacterFromUser];
};
