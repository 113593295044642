import React, { useEffect, useState } from 'react';
import { Card, Divider } from 'semantic-ui-react';
import EverquestTwoCharacterSelector from './EverquestTwoCharacterSelector';
import CharacterComponent from './CharacterComponent';
import { useUserCharacter } from '../hooks/useUserCharacter';

const EverquestTwoComponent = ({ characters = [] }) => {
  const [
    { data: updatedCharacters },
    addCharacterToUser,
    removeCharacterFromUser,
  ] = useUserCharacter(characters);

  const [charactersModel, setCharacterModel] = useState(characters);

  useEffect(() => {
    setCharacterModel(updatedCharacters);
  }, [updatedCharacters]);

  return (
    <>
      <EverquestTwoCharacterSelector onAddCharacter={addCharacterToUser} />
      <Divider />
      <Card.Group>
        {charactersModel.map(c => (
          <CharacterComponent
            key={c.id}
            id={c.id}
            name={c.name}
            realm={c.world}
            imageSize='tiny'
            onRemoveClick={removeCharacterFromUser}
            game='eq2'
          />
        ))}
      </Card.Group>
    </>
  );
};

export default EverquestTwoComponent;
