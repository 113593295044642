import React from 'react';
import { Route, Routes } from 'react-router';

import UsersIndexContainer from './index/UsersIndexContainer';
import UsersProfileContainer from './profile/UsersProfileComponent';
import UserEditContainer from './edit/UserEditContainer';
import { UserRestrictedComponent } from '../authentication/components/UserRestrictedComponent';
import UserGameComponent from './game/UserGameComponent';

const UsersContainer = () => {
  return (
    <Routes>
      <Route
        path='/:username/game/:gameId'
        element={<UserGameComponent />}
      />
      <Route
        path='/:username/edit'
        element={<UserRestrictedComponent element={<UserEditContainer />} />}
      />
      <Route
        path='/:username'
        element={<UsersProfileContainer />}
      />
      <Route
        path='/'
        element={<UsersIndexContainer />}
      />
    </Routes>
  );
};

export default UsersContainer;
