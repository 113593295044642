import React, { useCallback } from 'react';
import NavBar from './NavBar';

import { gameRoute, userRoute } from '../../store/siteNavigation';

import { useSearchApi } from './useSearchApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../store/authenticationContext';

const NavigationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authenticatedUser: user, logout } = useAuthentication();

  const [searchResults, performSearch] = useSearchApi();

  const onSearch = useCallback(text => performSearch(text), [performSearch]);

  const performNavigation = useCallback(
    selection => {
      if (selection.type === 'user') {
        navigate(userRoute(selection.name));
      } else if (selection.type === 'game') {
        navigate(gameRoute(selection.id));
      }
      // lame workaround so that the navigate executes when we're
      //  not changing the navigated content
      //  (i.e. I'm viewing a game and click the search results for a game)
      navigate(0);
    },
    [navigate]
  );

  return (
    <NavBar
      location={location}
      user={user}
      searchResults={searchResults.data}
      isSearching={searchResults.isLoading}
      onSearchTextChanged={onSearch}
      onSearchSelected={performNavigation}
      onLogoutClick={logout}
    />
  );
};

export default NavigationComponent;
