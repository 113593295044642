import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Image, List } from 'semantic-ui-react';

export default class Everquest2AchievementListItem extends PureComponent {

	static propTypes = {
		achievement: PropTypes.shape({
            id: PropTypes.number,
            title: PropTypes.string,
            description: PropTypes.string,
            icon: PropTypes.string,
        })
	};

	static defaultProps = {
		achievement: null
	};

	render() {
		const { achievement } = this.props;

        if(!achievement) {
            return null;
        }

		return (
            <List.Item key={achievement.id}>
                <Image rounded src={achievement.icon} />
                <List.Content>
                    <List.Header><a href={`https://eq2.fandom.com/wiki/${achievement.title.replace(" ","_")}`} target="_blank" rel="noopener noreferrer">{achievement.title}</a></List.Header>
                    <List.Description>{achievement.description}</List.Description>
                </List.Content>
            </List.Item>
		);
	}
}
