import React, { PureComponent } from 'react';
import Moment from 'react-moment';
import PropTypes from 'prop-types';
import { Card, List } from 'semantic-ui-react';
import {toPrettyDaysHoursMinutesRounded} from '../../../../../common/dateTimeFormatting';
import ImageWithFallback from '../../../../../common/images/ImageWithFallback';

export default class GameHistoryCard extends PureComponent {

	render() {
		const {history, onViewClick=() => {}} = this.props;

		return (
			<Card key={history.id}>
				<ImageWithFallback size='tiny' src={history.boxart} fallback="/images/game.png" centered style={{marginTop: '5px', marginBottom: '5px'}} />
				<Card.Content>
					<Card.Header as="a" onClick={() => onViewClick(history.id)}>{history.name}</Card.Header>
					<Card.Description>
						<List>
							<List.Item>
								<List.Content>{toPrettyDaysHoursMinutesRounded(history.totalActiveTime)} played</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>{history.totalSessions} sessions</List.Content>
							</List.Item>
							<List.Item>
								<List.Content>{toPrettyDaysHoursMinutesRounded(history.totalActiveTime/history.totalSessions)} average</List.Content>
							</List.Item>
						</List>
					</Card.Description>
				</Card.Content>
				<Card.Content extra>
					Last played <Moment calendar date={history.latestSession.EndDate} />
				</Card.Content>
			</Card>
		);
	}
}

GameHistoryCard.propTypes = {
	history: PropTypes.object,
	onViewClick: PropTypes.func,
};

GameHistoryCard.defaultProps = {
	onViewClick: () => {},
};
