import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

export default class PlatformIcon extends React.Component {
    static propTypes = {
        platform: PropTypes.string,
        isPlatformEnabled: PropTypes.bool,
        size: PropTypes.string,
    };

    static defaultProps = {
        platform: "Windows",
        isPlatformEnabled: true,
        size: "large"
    };

    render() {
        const { platform, isPlatformEnabled = true, size = "large" } = this.props;

        let iconName = 'question circle';

        switch (platform.toLowerCase()) {
            case 'osx':
                iconName = "apple";
                break;
            case 'windows':
                iconName = "windows";
                break;
            case 'linux':
                iconName = "linux";
                break;
            default:
                break;
        }

        return <Icon name={iconName} size={size} disabled={!isPlatformEnabled} />
    };
}