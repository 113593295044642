import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Dropdown, Form, Input, Button, Message } from 'semantic-ui-react';
import { useEverquestTwoWorlds } from '../hooks/useEverquestTwoWorlds';
import CharacterComponent from './CharacterComponent';
import { useEverquestTwoCharacter } from '../hooks/useEverquestTwoCharacter';

const EverquestTwoCharacterSelector = ({ onAddCharacter }) => {
  const [{ data: worlds, isLoading: areWorldsLoading }] =
    useEverquestTwoWorlds();
  const [
    { data: character, isLoading: isSearchingForCharacter, message },
    lookupCharacter,
  ] = useEverquestTwoCharacter();

  const [world, setWorld] = useState('');
  const [name, setName] = useState('');
  const [searchResult, setSearchResult] = useState(null);

  useEffect(() => {
    setSearchResult(character);
  }, [character]);

  const handleAddCharacter = async (realm, name) => {
    await onAddCharacter(realm, name, 'eq2');
    setSearchResult(null);
  };

  const isSearchEnabled = useMemo(
    () => !isSearchingForCharacter && world !== '' && name !== '',
    [world, name, isSearchingForCharacter]
  );

  const options = worlds
    .map(w => ({
      key: w.id,
      text: w.name,
      value: w.name,
      description: w.language,
    }))
    .sort((a, b) => (a.text > b.text ? 1 : -1));

  const onSelectWorld = useCallback((e, d) => {
    setWorld(d.value);
  }, []);

  const onSetName = useCallback((e, d) => {
    setName(d.value);
  }, []);

  return (
    <Form
      loading={areWorldsLoading}
      warning={message}
    >
      <Form.Group inline>
        <Form.Field
          control={Dropdown}
          options={options}
          loading={areWorldsLoading}
          search
          placeholder='Select world'
          selection
          label='World'
          onChange={onSelectWorld}
          value={world}
        />
        <Form.Field
          control={Input}
          placeholder='character name'
          label='Character'
          onChange={onSetName}
          value={name}
        />
        <Form.Field
          control={Button}
          loading={isSearchingForCharacter}
          disabled={!isSearchEnabled}
          onClick={() => lookupCharacter(world, name)}
        >
          Search
        </Form.Field>
      </Form.Group>
      {searchResult && (
        <CharacterComponent
          key={searchResult.id}
          id={searchResult.id}
          name={searchResult.name?.first}
          realm={searchResult.locationdata?.world}
          level={searchResult.type?.level}
          race={searchResult.type?.race}
          characterClass={searchResult.type?.class}
          onAddClick={handleAddCharacter}
        />
      )}
      <Message
        warning
        header='Character not found'
        content={message}
      />
    </Form>
  );
};

export default EverquestTwoCharacterSelector;
