import React from 'react';
import classNames from 'classnames';

import './FeatureSquare.css';

const FeatureSquare = ({
  title,
  description,
  image,
  alternateBackground = false,
}) => {
  return (
    <div
      className={classNames(
        'feature-container',
        alternateBackground && 'feature-container-alternate'
      )}
    >
      <div
        className={classNames(
          'feature-title',
          alternateBackground && 'feature-title-alternate'
        )}
      >
        {title}
      </div>
      <div className='feature-description'>{description}</div>
      <img
        className='feature-image'
        src={image}
        alt={title}
      />
    </div>
  );
};

export default FeatureSquare;
