import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useAuthentication } from '../../../store/authenticationContext';

export function UserRestrictedComponent({ element: Component, ...rest }) {
  const { username } = useParams();

  const { authenticatedUser: user } = useAuthentication();

  const isAuthenticated = user !== null;
  const isSameUser = isAuthenticated && user.username === username;

  return isSameUser ? (
    Component
  ) : (
    <Navigate
      to={isAuthenticated ? '/' : '/auth/login'}
      replace={true}
    />
  );
}
