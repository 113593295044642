import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {Card} from 'semantic-ui-react';
import GameHistoryCard from './GameHistoryCard';

export default class GameHistoryCardGroup extends PureComponent {

    render() {
        const {history, onViewUserGameHistory} = this.props;

        return (
            <Card.Group centered>
            {
              history.map(h => {
                return <GameHistoryCard key={h.id} history={h} onViewClick={onViewUserGameHistory}/>
              })
            }
            </Card.Group>
        )
    }
}

GameHistoryCardGroup.propTypes = {
    history: PropTypes.array,
    onViewUserGameHistory: PropTypes.func,
};

GameHistoryCardGroup.defaultProps = {
    history: [],
    onViewUserGameHistory: () => {},
};