import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
// import memoize from 'memoize-one';
import { Image } from 'semantic-ui-react';
import TopPlayerImage from './TopItemImage';

export default class TopItemList extends PureComponent {
    static propTypes = {
        items: PropTypes.array.isRequired,
        onItemClick: PropTypes.func,
    }

    static defaultProps = {
        items: [],
        onItemClick: () => {},
    }

    render() {
        const {items, onItemClick} = this.props;

        return (
            <Image.Group>
                {
                    items.map( i =>
                        <TopPlayerImage
                            id={i.id}
                            key={i.id}
                            name={i.name}
                            image={i.image}
                            totalDuration={i.totalDuration}
                            totalSessions={i.totalSessions}
                            lastPlayed={i.lastPlayed}
                            onClick={onItemClick}
                        />)
                }
            </Image.Group>
        )
    }
}