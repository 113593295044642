import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import moment from 'moment';
import { GrowthBookProvider } from '@growthbook/growthbook-react';

import './App.css';

import TopTenContainer from './views/top-ten/TopTenContainer';
import GamesContainer from './views/games/GamesContainer';
import UsersContainer from './views/users/UsersContainer';
import HomeContainer from './views/home/HomeContainer';
import SessionsContainer from './views/sessions/SessionsContainer';
import AuthenticationContainer from './views/authentication/AuthenticationContainer';
import NavigationComponent from './common/navigation/NavigationComponent';
import AboutComponent from './views/about/AboutComponent';
import { AuthenticationProvider } from './store/authenticationContext';
import growthbook from './store/growthbook';

const App = () => {
  useEffect(() => {
    moment.updateLocale('en', {
      calendar: {
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        nextWeek: 'dddd',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'L',
      },
    });

    growthbook.init({ streaming: true });
  }, [moment]);

  return (
    <GrowthBookProvider growthbook={growthbook}>
      <AuthenticationProvider>
        <BrowserRouter>
          <NavigationComponent />
          <Routes>
            <Route
              path='/'
              element={<HomeContainer />}
              exact
            />
            <Route
              path='/auth/*'
              element={<AuthenticationContainer />}
            />
            <Route
              path='/top-ten'
              element={<TopTenContainer />}
            />
            <Route
              path='/games/*'
              element={<GamesContainer />}
            />
            <Route
              path='/users/*'
              element={<UsersContainer />}
            />

            <Route
              path='/sessions/*'
              element={<SessionsContainer />}
            />

            <Route
              path='/about'
              element={<AboutComponent />}
            />
          </Routes>
        </BrowserRouter>
      </AuthenticationProvider>
    </GrowthBookProvider>
  );
};

export default App;
