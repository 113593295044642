import React from 'react';
import { Header } from 'semantic-ui-react';
import AchievementList from '../../../common/lists/achievements/AchievementList';

const AchievementsSegment = ({ achievements = [], gameId }) => {
  if (!achievements?.length) {
    return null;
  }

  return (
    <>
      <Header
        as='h4'
        dividing
      >{`Achievements (${achievements.length})`}</Header>
      <AchievementList
        achievements={achievements}
        gameId={gameId}
      />
    </>
  );
};

export default AchievementsSegment;
