import React, { useMemo } from 'react';

import { Header } from 'semantic-ui-react';
import CalendarHeatMap from './charts/CalendarHeatMap';
import SessionItemList from './sessions/SessionItemList';
import Gallery from './images/Gallery';

const SummaryComponent = ({
  latestSession = null,
  screenshots = [],
  dailyRankings = [],
  onViewSession = () => {},
  onViewUser = () => {},
  options = {},
}) => {
  const getHeatMapData = dailyRankings => {
    let results = [];

    for (const ranking of dailyRankings) {
      for (const values of ranking.dates) {
        let index = results.findIndex(r => r.key === values.date);

        if (index < 0) {
          results.push({
            key: values.date,
            date: new Date(values.date),
            value: values.duration,
            sessions: values.sessions,
          });
        } else {
          results[index].value += values.duration;
          results[index].sessions += values.sessions;
        }
      }
    }

    return results;
  };

  let heatData = useMemo(() => getHeatMapData(dailyRankings), [dailyRankings]);
  let latestSessionComponent = null;
  let latestScreenshotsComponent = null;

  if (latestSession) {
    latestSessionComponent = (
      <>
        <Header
          as='h4'
          dividing
        >
          Last Session
        </Header>
        <SessionItemList
          sessions={[latestSession]}
          onViewSession={onViewSession}
          showUser={options.showUser}
          onViewUser={onViewUser}
        />
      </>
    );
  }

  if (screenshots && screenshots.length > 0) {
    latestScreenshotsComponent = (
      <>
        <Header
          as='h4'
          dividing
        >
          Latest Screenshots
        </Header>
        <Gallery screenshots={screenshots} />
      </>
    );
  }

  return (
    <>
      <Header
        as='h4'
        dividing
      >
        Daily Activity
      </Header>
      <CalendarHeatMap
        values={heatData}
        options={{
          startDate: options.defaultStartDate,
          endDate: options.defaultEndDate,
        }}
      />
      {latestSessionComponent}
      {latestScreenshotsComponent}
    </>
  );
};

export default SummaryComponent;
