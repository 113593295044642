import React, { useState, useEffect } from 'react';
import qs from 'qs';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCode, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { Menu, Popup } from 'semantic-ui-react';

import { baseURL } from '../../../api';

const ExportUserSessionComponent = ({
  gameId,
  startDate: startDateString,
  endDate: endDateString,
}) => {
  const [startDate, setStartDate] = useState(moment().startOf('day').toDate());
  const [endDate, setEndDate] = useState(moment().endOf('day').toDate());
  const [csvUrl, setCsvUrl] = useState();
  const [jsonUrl, setJsonUrl] = useState();

  useEffect(() => {
    setStartDate(
      moment(startDateString).isValid()
        ? moment(startDateString).startOf('day').toDate()
        : moment().startOf('day').subtract(7, 'days').toDate()
    );
  }, [startDateString]);

  useEffect(() => {
    setEndDate(
      moment(endDateString).isValid()
        ? moment(endDateString).endOf('day').toDate()
        : moment().endOf('day').toDate()
    );
  }, [endDateString]);

  useEffect(() => {
    setCsvUrl(
      `${baseURL}/users/me/export${getQueryString({
        type: 'csv',
        gameId,
        startDate,
        endDate,
      })}`
    );
    setJsonUrl(
      `${baseURL}/users/me/export${getQueryString({
        gameId,
        startDate,
        endDate,
      })}`
    );
  }, [startDate, endDate]);

  const getQueryString = ({ gameId, type, startDate, endDate }) =>
    qs.stringify(
      {
        type,
        gameId,
        startDate: (!!startDate && startDate.toISOString()) || undefined,
        endDate: (!!endDate && endDate.toISOString()) || undefined,
      },
      { encode: false, skipNulls: true, addQueryPrefix: true }
    );

  return (
    <Menu
      compact
      size='small'
      stackable
      icon
    >
      <Menu.Item header>export</Menu.Item>
      <Popup
        trigger={
          <Menu.Item
            name='json'
            link={true}
            href={jsonUrl}
          >
            <FontAwesomeIcon
              icon={faFileCode}
              size='xl'
            />
          </Menu.Item>
        }
        content='Export to json'
      />
      <Popup
        trigger={
          <Menu.Item
            name='csv'
            link={true}
            href={csvUrl}
          >
            <FontAwesomeIcon
              icon={faFileCsv}
              size='xl'
            />
          </Menu.Item>
        }
        content='Export to CSV'
      />
    </Menu>
  );
};

export default ExportUserSessionComponent;
