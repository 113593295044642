import { useState, useEffect, useReducer } from 'react';
import { games as gameApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useGame = initialId => {
  const [gameId, setGameId] = useState(initialId);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: null,
  });

  useEffect(() => {
    const performFetch = async id => {
      try {
        dispatch({ type: FETCH });
        const response = await gameApi.getGame(id);

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    performFetch(gameId);
  }, [gameId]);

  return [state, setGameId];
};
