import React, { Suspense } from 'react';
import classNames from 'classnames';

export default function ImageWithFallback({
  src,
  fallback,
  size,
  style,
  centered,
  rounded,
  bordered,
  ...args
}) {
  return (
    <Suspense>
      <img
        className={classNames(
          'ui image',
          size,
          !!centered && 'centered',
          !!rounded && rounded,
          !!bordered && 'bordered'
        )}
        src={src || fallback}
        onError={e => {
          e.target.src = fallback;
        }}
        style={style}
        {...args}
      />
    </Suspense>
  );
}
