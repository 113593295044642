import React from 'react';
import PropTypes from 'prop-types';
import './RoundedThumbnail.css';

const RoundedThumbnail = ({url, style, title = '', size="50px"}) => (
  <img src={url} alt='' style={{...style, maxHeight: size, maxWidth: size}} title={title} className="rounded_thumbnail" />
);

RoundedThumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  size: PropTypes.string,
};

export default RoundedThumbnail;
