import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Grid, Dimmer, Loader, Card, Tab } from 'semantic-ui-react';

import { useAuthentication } from '../../../store/authenticationContext';

import { useGame } from '../../../common/hooks/useGame';
import { useDateSummary } from '../../../common/hooks/getDateSummary';
import { useUserProfile } from '../common/hooks/useUserProfile';
import { useScreenshotApi } from '../../../common/hooks/getScreenshots';
import { useSessionApi } from '../../../common/hooks/getSessions';
import { useVisualizationData } from '../../../common/hooks/getVisualizationData';
import { useStatistics } from '../../../common/hooks/useStatistics';

import SimpleUserCard from '../../../common/cards/user/SimpleUserCard';
import SimpleGameCard from '../../../common/cards/game/SimpleGameCard';
import AccordionGallery from '../../../common/screenshots/AccordianGallery';
import SummaryComponent from '../../../common/SummaryComponent';
import SessionFeedComponent from '../../../common/sessions/SessionFeedComponent';
import UserGameInsightComponent from './components/UserGameInsightComponent';
import StatisticsSegment from '../../../common/statistics/StatisticsSegment';

import {
  userRoute,
  gameRoute,
  sessionRoute,
} from '../../../store/siteNavigation';
import User from '../User';

const UserGameComponent = () => {
  const { username, gameId } = useParams();

  const navigate = useNavigate();

  const onNavigateToUser = useCallback(username =>
    navigate(userRoute(username))
  );
  const onNavigateToGame = useCallback(id => navigate(gameRoute(id)));
  const onNavigateToSession = useCallback(id => navigate(sessionRoute(id)));

  const [isUserViewingSelf, setIsUserViewingSelf] = useState(false);

  const { authenticatedUser: loggedInUser } = useAuthentication();

  const heatMapStart = moment()
    .subtract(6, 'months')
    .startOf('week')
    .startOf('day')
    .toDate();
  const heatMapEnd = moment().endOf('week').endOf('day').toDate();
  const timezone = moment().format('ZZ');
  const startDate = moment().subtract(1, 'week').startOf('day').toDate();
  const endDate = moment().endOf('day').toDate();
  const chartType = 'date';

  const [{ data: profile, isLoading: isUserLoading }] =
    useUserProfile(username);
  const [{ data: game, isLoading: isGameLoading }] = useGame(gameId);
  const [
    {
      data: screenshots,
      pagination: screenshotPaging,
      isLoading: isScreenshotsLoading,
    },
    loadMoreScreenshots,
  ] = useScreenshotApi(username, gameId);
  const [{ data: heatMap, isLoading: isHeatMapLoading }] = useDateSummary(
    username,
    gameId,
    'date',
    heatMapStart,
    heatMapEnd,
    timezone
  );
  const [
    { data: sessions, pagination: sessionPaging, isLoading: isSessionLoading },
    loadMoreSessions,
  ] = useSessionApi(username, gameId);
  const [
    { data: visualizationData, isLoading: isVisualizationLoading },
    setVisualizationType,
    setVisualizationStartDate,
    setVisualizationEndDate,
  ] = useVisualizationData(
    username,
    gameId,
    chartType,
    startDate,
    endDate,
    timezone
  );
  const [{ data: statistics }, setStatisticsStartDate, setStatisticsEndDate] =
    useStatistics(username, gameId, startDate, endDate);

  // Create the user model
  const user = useMemo(() => (profile ? new User(profile) : null), [profile]);

  const gameHistory = useMemo(
    () => (user ? user.stats.gameHistory.find(x => x.id === gameId) : null),
    [user, gameId]
  );

  useEffect(() => {
    setIsUserViewingSelf(loggedInUser && loggedInUser._id === user?._id);
  }, [loggedInUser, user]);

  // Get the latest session for the game
  const latestSession = gameHistory?.latestSession;

  // Get the last 5 screenshots
  const latestScreenshots = useMemo(
    () =>
      screenshots?.length > 0
        ? screenshots.slice(0, 5).map(s => s.screenshot)
        : [],
    [screenshots]
  );

  const summaryOptions = {
    showUser: false,
    defaultStartDate: heatMapStart,
    defaultEndDate: heatMapEnd,
  };

  const visualizationDatesChanged = (startDate, endDate) => {
    setVisualizationStartDate(startDate);
    setVisualizationEndDate(endDate);
    setStatisticsStartDate(startDate);
    setStatisticsEndDate(endDate);
  };

  const tabs = [
    {
      menuItem: 'Summary',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SummaryComponent
            latestSession={latestSession}
            dailyRankings={heatMap}
            screenshots={latestScreenshots}
            onViewSession={onNavigateToSession}
            isLoading={isHeatMapLoading}
            options={summaryOptions}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Insights',
      render: () => (
        <Tab.Pane attached='bottom'>
          <UserGameInsightComponent
            statistics={statistics}
            visualizationData={visualizationData}
            isVisualizationLoading={isVisualizationLoading}
            onDateChanged={visualizationDatesChanged}
            onVisualizationChanged={setVisualizationType}
            isExportVisible={isUserViewingSelf}
            gameId={gameId}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Activity',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SessionFeedComponent
            sessions={sessions}
            onLoadMore={loadMoreSessions}
            isLoading={isSessionLoading}
            showLoadMore={sessionPaging.page !== sessionPaging.pages}
            onViewSession={onNavigateToSession}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'screenshots',
      render: () => (
        <Tab.Pane attached='bottom'>
          <AccordionGallery
            screenshots={screenshots}
            isLoading={isScreenshotsLoading}
            showLoadMore={screenshotPaging.page !== screenshotPaging.pages}
            onLoadMore={() => loadMoreScreenshots()}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Dimmer active={isUserLoading}>
        <Loader />
      </Dimmer>
      <Grid
        columns={2}
        stackable
        centered
      >
        <Grid.Row>
          <Grid.Column width={3}>
            <Card.Group centered>
              <SimpleUserCard
                username={user?.username}
                avatar={user?.avatar}
                id={user?._id}
                isLoading={isUserLoading}
                onClick={username => onNavigateToUser(username)}
              />
              <SimpleGameCard
                id={game?._id}
                name={game?.Name}
                boxart={game?.boxart}
                isLoading={isGameLoading}
                onClick={id => onNavigateToGame(id)}
              />
            </Card.Group>
            <StatisticsSegment
              totalSessions={gameHistory?.totalSessions}
              totalTime={gameHistory?.totalActiveTime}
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <Tab
              menu={{ secondary: true, attached: 'top', pointing: true }}
              panes={tabs}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UserGameComponent;
