import { useState, useEffect, useReducer } from 'react';
import { users as userApi, games as gameApi } from '../../api';
import {
  FETCH,
  FETCH_ERROR,
  FETCH_SUCCESS,
  apiReducer,
} from '../reducers/apiReducer';

export const useDateSummary = (
  initialUsername,
  initialGameId,
  initialType = 'date',
  initialStartDate,
  initialEndDate,
  timezone
) => {
  const [username] = useState(initialUsername);
  const [gameId] = useState(initialGameId);
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [type, setType] = useState(initialType);

  const [state, dispatch] = useReducer(apiReducer, {
    isLoading: false,
    error: null,
    data: [],
  });

  useEffect(() => {
    const fetch = async (user, gameId, type, startDate, endDate, timezone) => {
      try {
        dispatch({ type: FETCH });
        const response =
          gameId && user
            ? await userApi.getGameDateSummary(
                user,
                gameId,
                type,
                startDate,
                endDate,
                timezone
              )
            : user
            ? await userApi.getDateSummary(
                user,
                type,
                startDate,
                endDate,
                timezone
              )
            : await gameApi.getDateSummary(
                gameId,
                type,
                startDate,
                endDate,
                timezone
              );

        dispatch({ type: FETCH_SUCCESS, payload: response.data });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: e });
        console.error(e);
      }
    };

    fetch(username, gameId, type, startDate, endDate, timezone);
  }, [username, gameId, type, startDate, endDate, timezone]);

  return [state, setType, setStartDate, setEndDate];
};
