import React, { PureComponent } from "react";
import PropTypes from 'prop-types';
import {Grid, Header, Icon, Form, Button, Image} from 'semantic-ui-react';

export default class RegistrationForm extends PureComponent {

    static propTypes = {
        avatar: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        visible: PropTypes.bool,
        usernameError: PropTypes.string,
        emailError: PropTypes.string,
        onRegister: PropTypes.func,
        onCancel: PropTypes.func,
    };

    static defaultProps = {
        visible: false,
        onRegister: () => {},
        onCancel: () => {},
        usernameError: '',
        emailError: '',
    };

    constructor(props) {
        super(props);

        this.state = {
            avatar: props.avatar,
            username: props.username,
            email: props.email,
        };
    }

    componentDidUpdate(prevProps) {
        if(prevProps.username !== this.props.username) {
            this.setState({username: this.props.username});
        }

        if(prevProps.email !== this.props.email) {
            this.setState({email: this.props.email});
        }

        if(prevProps.avatar !== this.props.avatar) {
            this.setState({avatar: this.props.avatar});
        }
    }

    handleSubmit = () => {
        const {username, email, avatar} = this.state;

        if(this.props.onRegister) {
            this.props.onRegister(username, email, avatar);
        }
    }

    setEmail = (event, data) => {
        this.setState( () => ({
            email: data.value,
        }));
    }

    setUsername = (event, data) => {
        this.setState( () => ({
            username: data.value,
        }));
    }

    getError = (fieldName) => {
        return this.props[fieldName] !== '' ?
                    {content: this.props[fieldName], pointing: 'above'} :
                    null;
    }

    render() {
        const {onCancel, visible} = this.props;
        const {username, email, avatar} = this.state;

        if(!visible) {
            return null;
        }

        const userErrorContent = this.getError('usernameError');
        const emailErrorContent = this.getError('emailError');

        return (
        <Grid centered columns={2} divided relaxed padded="vertically">
            <Grid.Row columns={1}>
                <Header as='h2' icon>
                    <Icon name='user outline' />
                    Register
                    <Header.Subheader>
                        Complete your registration to get started with TempusGameIt
                    </Header.Subheader>
                </Header>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={2}>
                    <Image src={avatar} rounded />
                </Grid.Column>
                <Grid.Column width={5}>
                    <Form size='large' onSubmit={this.handleSubmit}>
                        <Form.Input
                            label="Username"
                            value={username}
                            onChange={this.setUsername}
                            error={userErrorContent}
                        />
                        <Form.Input
                            label="Email"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={this.setEmail}
                            type="email"
                            error={emailErrorContent}
                        />
                        <Button type='submit' primary>Register</Button>
                        <Button basic onClick={onCancel}>Cancel</Button>
                    </Form>
                </Grid.Column>
            </Grid.Row>
        </Grid>
        )
    }
}