import React from 'react';
import { Icon, Image, Item, Label, List } from 'semantic-ui-react';
import Moment from 'react-moment';

import {toPrettyDaysHoursMinutesRounded} from '../dateTimeFormatting';
import PlatformLabel from './PlatformLabel';
import ImageWithFallback from '../images/ImageWithFallback';
import ReactMarkdown from 'react-markdown';

import './SessionItem.css';

const SessionItem = ({session = {}, onViewSession = () => {}, onViewUser = () => {}, showUser}) => {

    const getUserItem = (showUser, user) => {
        if(!showUser) {
            return null;
        }

        return (
            <List.Item>
                <ImageWithFallback rounded src={user.avatar} fallback="/images/user.png" style={{maxHeight: '32px'}} />
                <List.Content>
                    <List.Header as="a" onClick={() => onViewUser(user.username)}>
                        {user.username}
                    </List.Header>
                </List.Content>
            </List.Item>
        )
    }

    const getCommentsBlock = (comments) => {
        if(!comments || comments === '') {
            return null;
        }

        const carriageReturn = session.comments.indexOf('\n');
        let formattedComments = session.comments.substring(0, carriageReturn === -1 ? session.comments.length : carriageReturn );

        return (
            <Item.Description>
                <ReactMarkdown className="session-item-comments">{session.comments.replace('\n', '\n\r')}</ReactMarkdown>
            </Item.Description>
        )
    }

    const achievements = session.achievements || [];
    const screenshots = session.screenshots || [];

    const userItem = getUserItem(showUser, session.user);
    const commentsBlock = getCommentsBlock(session.comments);

    const privateLabel = session.IsInvisible ?
                            <Label>
                                <Icon name="privacy" />
                                Private
                            </Label> :
                            null;

    return (
        <Item>
            <Item.Image size="tiny" src={session.game.boxart} />
            <Item.Content>
                <Item.Header as="a" onClick={() => onViewSession(session._id)}>{session.game.name}</Item.Header>
                <Item.Meta>
                    {session.Version || 'No version specified'}
                </Item.Meta>
                <Item.Description>
                    <List horizontal size="medium" divided relaxed>
                        {userItem}
                        <List.Item>
                            <List.Content>{toPrettyDaysHoursMinutesRounded(session.ActiveDuration)}</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content>
                                <Moment format="l">{session.StartDate}</Moment>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Content><Moment format="LT">{session.StartDate}</Moment> - <Moment format="LT">{session.EndDate}</Moment></List.Content>
                        </List.Item>
                    </List>
                </Item.Description>
                <Item.Extra>
                    {privateLabel}
                    <PlatformLabel platform={session.platform} />
                    {!!screenshots.length && <Label>
                        <Icon name='picture'/>
                        {screenshots.length}
                    </Label>}
                    {!!achievements.length && <Label>
                        <Icon name='trophy'/>
                        {achievements.length}
                    </Label>}
                </Item.Extra>
                {commentsBlock}
            </Item.Content>
        </Item>
    )
}

export default SessionItem;