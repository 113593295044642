import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  Button,
  Form,
  Header,
  Icon,
  Segment,
  TextArea,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import './CommentsComponent.css';

const CommentsComponent = ({
  comments = '',
  isSaving = false,
  isEditable = false,
  onChange = () => {},
}) => {
  const [isEditing, setEditing] = useState(false);

  const onCommentsChangeClick = updatedComments => {
    setEditing(false);
    if (updatedComments !== comments) {
      onChange(updatedComments);
    }
  };

  // The component isn't editable
  if (!isEditable) {
    // Depending on the presence of comments, react accordingly
    return comments ? (
      <ReactMarkdown>{comments.replace('\n', '\n\r')}</ReactMarkdown>
    ) : null;
  }

  const nonEditingComponent = comments ? (
    <CommentDisplayComponent
      comments={comments}
      onEdit={() => setEditing(true)}
    />
  ) : (
    <CommentsPlaceholderComponent onEdit={() => setEditing(true)} />
  );

  if (isEditing) {
    return (
      <CommentsEditComponent
        comments={comments}
        onChange={onCommentsChangeClick}
        onCancel={() => setEditing(false)}
      />
    );
  } else {
    return (
      <div>
        <Dimmer active={isSaving}>
          <Loader />
        </Dimmer>
        {nonEditingComponent}
      </div>
    );
  }
};

const CommentDisplayComponent = ({ comments = '', onEdit = () => {} }) => {
  return (
    <div
      id='comments-div'
      style={{ cursor: 'pointer' }}
      onClick={onEdit}
    >
      <ReactMarkdown>{comments.replace('\n', '\n\r')}</ReactMarkdown>
    </div>
  );
};

const CommentsEditComponent = ({
  comments = '',
  onChange = () => {},
  onCancel = () => {},
}) => {
  const [commentsField, setCommentsField] = useState(comments);

  return (
    <Form>
      <Form.Group>
        <TextArea
          rows='3'
          value={commentsField}
          onInput={(e, d) => setCommentsField(d.value)}
        />
      </Form.Group>
      <Form.Group>
        <Button
          icon='check'
          title='Save'
          primary
          onClick={() => onChange(commentsField)}
        />
        <Button
          icon='cancel'
          title='Cancel'
          onClick={onCancel}
        />
        <Button
          icon='trash'
          title='Clear your comments'
          onClick={() => onChange('')}
        />
      </Form.Group>
    </Form>
  );
};

const CommentsPlaceholderComponent = ({ onEdit = () => {} }) => {
  return (
    <Segment
      placeholder
      size='tiny'
    >
      <Header icon>
        <Icon name='comments' />
        Have something more to say about what you did?
      </Header>
      <Button onClick={onEdit}>Add comments</Button>
    </Segment>
  );
};

export default CommentsComponent;
