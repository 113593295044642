import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import HeatMap from 'react-heatmap-grid';
import moment from 'moment';
import memoize from 'memoize-one';

import {toPrettyDaysHoursMinutesRounded} from '../dateTimeFormatting';

export default class CalendarHeatMap extends PureComponent {

    static propTypes = {
        values: PropTypes.arrayOf(PropTypes.shape({
            date: PropTypes.object,
            value: PropTypes.number,
        })),
        options: PropTypes.shape({
            startDate: PropTypes.object || PropTypes.string,
            endDate: PropTypes.object || PropTypes.string,
        }),
    }

    static defaultProps = {
        values: [],
        options: {},
    }

    constructor(props) {
        super(props);

        this.state = {
            yLabels: moment.weekdaysShort()
        }

        if(props.options.startDate && props.options.endDate) {
            this.state.defaultValues = this.createEmptyHeatMap(props.options.startDate, props.options.endDate);
        }
    }

    createEmptyHeatMap = (startDate, endDate) => {
        let data = [];

        let currentDate = moment(startDate).startOf('day');
        const loopEndDate = moment(endDate).endOf('day');

        while(currentDate <= loopEndDate) {
            data.push({
                date: currentDate.toDate(),
                value: 0,
            });

            currentDate = currentDate.add(1, 'day');
        }

        return data;
    }

    getHeatMapData = memoize(
        (values) => {

            let results = {
                data: [[],[],[],[],[],[],[]],
                xLabels: [],
                xLabelVisibility: [],
            }

            if(!values || values.length === 0) {
                return results;
            }

            // Ensure that the dates are sorted
            const sortedValues = values.sort( (x,y) => x.date.getTime() - y.date.getTime() );

            // Get the diffence in weeks between the first date and last date.
            const firstDate = new moment(sortedValues[0].date);
            const lastDate = new moment(sortedValues[sortedValues.length -1].date);

            const numberOfWeeks = Math.ceil(lastDate.diff(firstDate, 'weeks', true));

            const startWeek = firstDate.week();

            // Next, zero pad out all of the day of week arrays
            for(let weekArray of results.data) {
                for(let i=0; i < numberOfWeeks; i++) {
                    weekArray.push(0);
                }
            }

            let priorMoment = new moment(sortedValues[0].date);
            results.xLabels.push(priorMoment.format('MMM'));

            // Now, let's build our array of arrays, splitting them into weeks
            for(let value of sortedValues) {

                let currentMoment = new moment(value.date);

                value.dayOfWeek = currentMoment.day();

                // Get the correct week array
                let weekArray = results.data[currentMoment.day()];

                const arrayIndex = currentMoment.week() - startWeek;

                // Push the value to the next available spot in the array
                weekArray[arrayIndex >= 0 ? arrayIndex : 52 + arrayIndex] = value.value;

                // Determine if there was a week change.  If so, push a new entry into the labels
                if(currentMoment.week() !== priorMoment.week()) {
                    results.xLabels.push(currentMoment.format('MMM'));
                }

                priorMoment = currentMoment.clone();
            }

            if(results.xLabels.length > 1) {
                results.xLabelVisibility.push(true);
                for(let i=1; i < results.xLabels.length; i++) {
                    results.xLabelVisibility.push( results.xLabels[i] !== results.xLabels[i-1] );
                }
            }

            return results;
        }
    );

    render() {
        const {yLabels, defaultValues} = this.state;

        // If values are not supplied, then go ahead and use the default values to build the grid
        const values = !this.props.values || this.props.values.length <= 0 ? defaultValues : this.props.values;

        const heatmapData = this.getHeatMapData(values);

        return (
            <HeatMap yLabels={yLabels}
                     xLabels={heatmapData.xLabels}
                     xLabelsVisibility={heatmapData.xLabelVisibility}
                     data={heatmapData.data}
                     square
                     cellStyle={(background, value, min, max, data, x, y) => ({
                        background: value === 0 ? "#F0F0F0" : `rgba(130,157,180, ${(1 - (max - value) / (max - min)) + .25})`,
                    })}
                    title={(value, unit) => `${toPrettyDaysHoursMinutesRounded(value)}`}
            />
        )
    }
}