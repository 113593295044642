import React, { useMemo } from 'react';
import { Card } from 'semantic-ui-react';
import PlatformImages from '../../images/PlatformImages';
import Badge from '../../Badge';
import './GameCard.css';
import ImageWithFallback from '../../images/ImageWithFallback';

const GameCard = ({ game }) => {
  if (!game) {
    return null;
  }

  const steamUrl = useMemo(
    () =>
      game?.steamId
        ? `https://store.steampowered.com/app/${game.steamId}`
        : null,
    [game]
  );

  const igdbUrl = useMemo(
    () =>
      game?.externalId && game?.externalSource === 'igdb' && game?.externalUrl
        ? game.externalUrl
        : null,
    [game]
  );

  return (
    <Card>
      <ImageWithFallback
        src={game.boxart}
        fallback='/images/game.png'
      />
      <Card.Content>
        <Card.Header>{game.Name}</Card.Header>
      </Card.Content>
      <Card.Content
        extra
        className='GameCard-extra'
      >
        <div>
          <PlatformImages platforms={game.platforms} />
        </div>
        <div className='GameCard-extra-badges'>
          {igdbUrl && (
            <Badge
              id='igdb'
              name='igdb'
              color='#9046ff'
              url={igdbUrl}
            />
          )}
          {steamUrl && (
            <Badge
              id='steam'
              name='Steam'
              color='#171a21'
              url={steamUrl}
            />
          )}
        </div>
      </Card.Content>
    </Card>
  );
};

export default GameCard;
