import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Tab, Dimmer, Loader, Button } from 'semantic-ui-react';
import moment from 'moment';

import {
  sessionRoute,
  userEditRoute,
  userGameRoute,
} from '../../../store/siteNavigation';

import { useUserProfile } from '../common/hooks/useUserProfile';
import { useStatistics } from '../../../common/hooks/useStatistics';
import { useDateSummary } from '../../../common/hooks/getDateSummary';
import { useScreenshotApi } from '../../../common/hooks/getScreenshots';
import { useSessionApi } from '../../../common/hooks/getSessions';
import { useVisualizationData } from '../../../common/hooks/getVisualizationData';
import { useTopGames } from '../common/hooks/useTopGames';
import { useWeeklySummary } from '../../../common/hooks/useWeeklySummary';

import UserCard from '../../../common/cards/user/UserCard';
import StatisticsSegment from '../../../common/statistics/StatisticsSegment';

import SessionFeedComponent from '../../../common/sessions/SessionFeedComponent';
import GameHistoryComponent from './history/GameHistoryComponent';
import WeeklySummaryComponent from '../../../common/weekly/WeeklySummaryComponent';
import SummaryComponent from '../../../common/SummaryComponent';
import AccordionGallery from '../../../common/screenshots/AccordianGallery';
import UserInsightComponent from './components/UserInsightComponent';

import User from '../User';
import { useAuthentication } from '../../../store/authenticationContext';

const UsersProfileContainer = () => {
  const { username } = useParams();

  const navigate = useNavigate();

  const [isUserViewingSelf, setIsUserViewingSelf] = useState(false);

  const heatMapStart = moment()
    .subtract(6, 'months')
    .startOf('week')
    .startOf('day')
    .toDate();
  const heatMapEnd = moment().endOf('week').endOf('day').toDate();
  const timezone = moment().format('ZZ');
  const startDate = moment().subtract(1, 'week').startOf('day').toDate();
  const endDate = moment().endOf('day').toDate();
  const chartType = 'date';

  const { authenticatedUser: loggedInUser } = useAuthentication();

  const [{ data: profile, isLoading: isUserLoading }] =
    useUserProfile(username);

  const [{ data: statistics }, setStatisticsStartDate, setStatisticsEndDate] =
    useStatistics(username, null, startDate, endDate);

  const [{ data: heatMap, isLoading: isHeatMapLoading }] = useDateSummary(
    username,
    null,
    'date',
    heatMapStart,
    heatMapEnd,
    timezone
  );

  const [
    {
      data: screenshots,
      pagination: screenshotPaging,
      isLoading: isScreenshotsLoading,
    },
    loadMoreScreenshots,
  ] = useScreenshotApi(username, null);

  // Create the user model
  const user = useMemo(() => (profile ? new User(profile) : null), [profile]);

  // Visualization properties
  const [
    { data: visualizationData, isLoading: isVisualizationLoading },
    setVisualizationType,
    setVisualizationStartDate,
    setVisualizationEndDate,
  ] = useVisualizationData(
    username,
    null,
    chartType,
    startDate,
    endDate,
    timezone
  );

  // Top games properties
  const [{ data: topGames }, setTopGamesStartDate, setTopGamesEndDate] =
    useTopGames(username, startDate, endDate);

  // Session properties
  const [
    { data: sessions, pagination: sessionPaging, isLoading: isSessionLoading },
    loadMoreSessions,
  ] = useSessionApi(username, null);

  const [{ data: summary, isLoading: isSummaryLoading }] =
    useWeeklySummary(username);

  // Get the latest top 5 screenshots
  const recentScreenshots = useMemo(
    () =>
      screenshots?.length > 0
        ? screenshots.slice(0, 5).map(s => s.screenshot)
        : [],
    [screenshots]
  );

  const summaryOptions = {
    showUser: false,
    defaultStartDate: heatMapStart,
    defaultEndDate: heatMapEnd,
  };

  const onViewSession = useCallback(
    id => navigate(sessionRoute(id)),
    [navigate]
  );

  const onViewUserGameHistory = useCallback(
    gameId => navigate(userGameRoute(username, gameId)),
    [navigate]
  );

  const onEditProfile = useCallback(
    username => navigate(userEditRoute(username)),
    [navigate]
  );

  const visualizationDatesChanged = useCallback(
    (startDate, endDate) => {
      setVisualizationStartDate(startDate);
      setVisualizationEndDate(endDate);
      setStatisticsStartDate(startDate);
      setStatisticsEndDate(endDate);
      setTopGamesStartDate(startDate);
      setTopGamesEndDate(endDate);
    },
    [
      setVisualizationStartDate,
      setVisualizationEndDate,
      setStatisticsStartDate,
      setStatisticsEndDate,
      setTopGamesStartDate,
      setTopGamesEndDate,
    ]
  );

  useEffect(() => {
    setIsUserViewingSelf(loggedInUser && loggedInUser._id === user?._id);
  }, [loggedInUser, user]);

  const tabs = [
    {
      menuItem: 'Summary',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SummaryComponent
            latestSession={user?.latestSession}
            dailyRankings={heatMap}
            screenshots={recentScreenshots}
            onViewSession={id => onViewSession(id)}
            isLoading={isHeatMapLoading}
            options={summaryOptions}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'This Week',
      render: () => (
        <Tab.Pane attached='bottom'>
          <WeeklySummaryComponent
            rankings={summary.rankings}
            latestSession={summary.latestSession}
            stats={summary.stats}
            dates={summary.dates}
            onViewSession={id => onViewSession(id)}
            onItemClick={id => onViewUserGameHistory(id)}
            isLoading={isSummaryLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Insights',
      render: () => (
        <Tab.Pane attached='bottom'>
          <UserInsightComponent
            statistics={statistics}
            visualizationData={visualizationData}
            isVisualizationLoading={isVisualizationLoading}
            onDateChanged={visualizationDatesChanged}
            onVisualizationChanged={setVisualizationType}
            isExportVisible={isUserViewingSelf}
            topGames={topGames}
            onTopGameClicked={id => onViewUserGameHistory(id)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Activity',
      render: () => (
        <Tab.Pane attached='bottom'>
          <SessionFeedComponent
            sessions={sessions}
            onLoadMore={loadMoreSessions}
            isLoading={isSessionLoading}
            showLoadMore={sessionPaging.page !== sessionPaging.pages}
            onViewSession={id => onViewSession(id)}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Catalog',
      render: () => (
        <Tab.Pane attached='bottom'>
          <GameHistoryComponent
            history={user?.history}
            onViewUserGameHistory={onViewUserGameHistory}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'screenshots',
      render: () => (
        <Tab.Pane attached='bottom'>
          <AccordionGallery
            screenshots={screenshots}
            isLoading={isScreenshotsLoading}
            showLoadMore={screenshotPaging.page !== screenshotPaging.pages}
            onLoadMore={loadMoreScreenshots}
            grouping={'game'}
          />
        </Tab.Pane>
      ),
    },
  ];

  const editButton = isUserViewingSelf ? (
    <Button
      fluid
      onClick={() => onEditProfile(user?.username)}
    >
      Edit Profile
    </Button>
  ) : null;

  return (
    <>
      <Dimmer active={isUserLoading}>
        <Loader />
      </Dimmer>
      <Grid
        columns={2}
        stackable
        centered
      >
        <Grid.Row
          only='computer'
          centered
        >
          <Grid.Column width={3}>
            <Grid.Column>
              <UserCard user={user} />
              {editButton}
              <StatisticsSegment
                totalTime={user?.stats.totalTime}
                totalSessions={user?.stats.totalSessions}
              />
            </Grid.Column>
          </Grid.Column>
          <Grid.Column width={10}>
            <Tab
              menu={{ secondary: true, attached: 'top', pointing: true }}
              panes={tabs}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          only='tablet mobile'
          centered
        >
          <Grid.Column width={7}>
            <UserCard user={user} />
          </Grid.Column>
          <Grid.Column width={7}>
            <StatisticsSegment
              totalTime={user?.stats.totalTime}
              totalSessions={user?.stats.totalSessions}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
          only='tablet mobile'
          centered
        >
          <Grid.Column width={14}>
            <Tab
              menu={{ secondary: true, attached: 'top', pointing: true }}
              panes={tabs}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default UsersProfileContainer;
